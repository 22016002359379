import { Box, BoxProps, Button, InlineStack, MenuActionDescriptor, Text, TextProps } from '@shopify/polaris';

type Props = {
  title?: string | React.ReactNode;
  actions?: MenuActionDescriptor[];
  isSectionHeader?: boolean;
};

const UiConfig = {
  root: {
    paddingBottom: '300',
    titleElement: 'h2',
    titleSize: 'headingMd',
  },
  section: {
    paddingBottom: '150',
    titleElement: 'h3',
    titleSize: 'headingSm',
  },
};

export const FeaturedCardHeader = ({ title, actions = [], isSectionHeader }: Props) => {
  const config = UiConfig[isSectionHeader ? 'section' : 'root'];

  if (!title && !actions.length) return null;

  return (
    <Box paddingBlockEnd={config.paddingBottom as BoxProps['paddingBlockEnd']} shadow="0">
      <InlineStack align="space-between" blockAlign="center">
        <div style={{ flex: actions.length ? '0 0 auto' : '1' }}>
          <Text as={config.titleElement as TextProps['as']} variant={config.titleSize as TextProps['variant']}>
            {title}
          </Text>
        </div>
        <InlineStack gap="200">
          {actions.map((action) => (
            <Button {...action} variant="plain" onClick={action.onAction}>
              {action.content}
            </Button>
          ))}
        </InlineStack>
      </InlineStack>
    </Box>
  );
};
