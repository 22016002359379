import { LogoutModal } from '@/modals/LogoutModal';
import { Button, InlineStack, Thumbnail } from '@shopify/polaris';

import { useToggle } from 'hooks/useToggle';

type Props = {
  logo?: string;
};

const Logo = ({ logo }: Props) => {
  const [isLogoutModalOpen, toggleLogoutModal] = useToggle(false);

  return (
    <div className="Polaris-Page">
      <InlineStack blockAlign="center" align={!!logo ? 'space-between' : 'start'}>
        {!!logo && <Thumbnail source={logo} size="large" alt="logo" />}

        <Button onClick={toggleLogoutModal} variant="tertiary">
          Logout
        </Button>
      </InlineStack>

      <LogoutModal isOpen={isLogoutModalOpen} onClose={toggleLogoutModal} />
    </div>
  );
};

export default Logo;
