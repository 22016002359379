import { FeaturedCard } from '@/shared/FeaturedCard/FeaturedCard';
import { FeaturedCardSection } from '@/shared/FeaturedCard/FeaturedCardSection';
import { Button, TextField } from '@shopify/polaris';
import { api } from 'api/index';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { t } from 'react-simple-multi-language';
import { showToast } from 'states/actions/toast';
import { DiscountApplyingStatus, DiscountInfo } from 'types/DiscountInfo';

const disabledInputStatuses = [DiscountApplyingStatus.SUCCESS, DiscountApplyingStatus.LOADING];

type Props = {
  subscriptionId: number;
  onDiscountApplied: (data: DiscountInfo) => void;
};

const DiscountCode = ({ onDiscountApplied, subscriptionId }: Props) => {
  const dispatch = useDispatch();

  const [discountCode, setDiscountCode] = useState('');
  const [discountStatus, setDiscountStatus] = useState(DiscountApplyingStatus.INIT);

  const submit = async () => {
    if (!discountCode) return;
    setDiscountStatus(DiscountApplyingStatus.LOADING);
    try {
      const resp = await api.applyDiscountCode(subscriptionId, discountCode);
      onDiscountApplied(resp.data);
      setDiscountStatus(DiscountApplyingStatus.SUCCESS);
    } catch (er: any) {
      if (er?.response?.status === 403 && er?.response?.data?.message) {
        showToast(dispatch)(er?.response?.data?.message);
        setDiscountStatus(DiscountApplyingStatus.INIT);
      } else {
        setDiscountStatus(DiscountApplyingStatus.ERROR);
      }
    }
  };

  return (
    <div className="apply-discount-code-wrapper">
      <FeaturedCard title={t('discount_code')}>
        <FeaturedCardSection>
          <TextField
            autoComplete="off"
            label={t('discount_code_title')}
            disabled={disabledInputStatuses.includes(discountStatus)}
            error={discountStatus === DiscountApplyingStatus.ERROR && t('discount_code_error')}
            value={discountCode}
            onChange={(val) => setDiscountCode(val)}
            connectedRight={
              discountStatus !== DiscountApplyingStatus.SUCCESS && (
                <Button onClick={submit} loading={discountStatus === DiscountApplyingStatus.LOADING}>
                  {t('apply')}
                </Button>
              )
            }
          />
        </FeaturedCardSection>
      </FeaturedCard>
    </div>
  );
};

export default DiscountCode;
