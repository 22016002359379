import { api } from 'api/index';
import { useEffect, useState } from 'react';
import { languageStore, useUpdateComponentWhenLanguageChange } from 'react-simple-multi-language';
import { injectCustomStyle } from 'utils/injectCustomStyle';
import { portalCustomizationContentAdapter } from 'utils/portalCustomizationAdapter';

export const useLoadContents = () => {
  const [loading, setLoading] = useState(true);

  useUpdateComponentWhenLanguageChange();

  useEffect(() => {
    api
      .getContents()
      .then((res) => {
        injectCustomStyle(res.data.custom_css);
        languageStore.setState({
          currentLanguage: 'en',
          translations: { en: portalCustomizationContentAdapter(res.data.contents) },
        });
        setLoading(false);
      })
      .catch((e) => {
        if (e?.response?.status !== 401) {
          alert('Failed to load contents.please reload page');
        }
      });
  }, []);

  return loading;
};
