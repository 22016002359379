import { Text } from '@shopify/polaris';

import { parseTimeDelta } from 'utils/dateTimeUtils';

type CounterProps = {
  remainingTime: number;
};

export const Counter = ({ remainingTime }: CounterProps) => {
  const { minutes, seconds } = parseTimeDelta(remainingTime);

  return (
    <div>
      <TimeMonitor amount={minutes} />
      <Text as="span" variant="bodyMd">
        :
      </Text>
      <TimeMonitor amount={seconds} />
    </div>
  );
};

type TImerProps = {
  amount: number;
};

export const TimeMonitor = ({ amount }: TImerProps) => {
  return (
    <Text as="span" variant="bodyMd">
      {amount.toString().replace(/\b(\d)\b/g, '0$1')}
    </Text>
  );
};
