import { ConfirmationStep } from '@/cancelSubscriptionModal/ConfirmationStep';
import { CustomMessageStep } from '@/cancelSubscriptionModal/CustomMessageStep';
import { SelectReasons } from '@/cancelSubscriptionModal/SelectReasons';
import { api } from 'api/index';
import { useState } from 'react';
import { t } from 'react-simple-multi-language';
import {
  CancellationManagement,
  CancellationReasonsData,
  CancellationStepConfigs,
  CancellationSteps,
} from 'types/CancelationManagement';

export enum LoadingTypes {
  NONE = 'NONE',
  BUTTON_LOADING = 'BUTTON_LOADING',
  CONTENT_LOADING = 'CONTENT_LOADING',
}

export const useCancelContract = (onCancel: (data?: CancellationReasonsData) => void) => {
  const [cancellationStep, setCancellationStep] = useState(CancellationSteps.CONFORMATION_MESSAGE);

  const [loading, setLoading] = useState(LoadingTypes.NONE);

  const [cancelationManagementData, setCancelationManagementData] = useState<CancellationManagement | null>(null);

  const [cancellationReasonsForm, setCancellationReasonsForm] = useState<CancellationReasonsData>({
    reasons: [],
    customer_reason: '',
  });

  const cancellationStepConfigs: CancellationStepConfigs = {
    [CancellationSteps.CONFORMATION_MESSAGE]: {
      title: t('cancel_modal_title_2'),
      onAction: () => {
        setLoading(LoadingTypes.BUTTON_LOADING);
        api.getCancelationMAnagementData().then(({ data }) => {
          setCancelationManagementData(data);

          if (data.is_cancellation_reason_active || data.is_custom_cancel_message_active) {
            setLoading(LoadingTypes.CONTENT_LOADING);
            setCancellationStep(
              data.is_cancellation_reason_active ? CancellationSteps.SELECT_REASON : CancellationSteps.CUSTOM_MESSAGE
            );
            setTimeout(() => {
              setLoading(LoadingTypes.NONE);
            }, 2000);
          } else {
            onCancel();
          }
        });
      },
      component: ConfirmationStep,
    },
    [CancellationSteps.SELECT_REASON]: {
      title: t('cancel_modal_title_3'),
      onAction: () => {
        if (cancelationManagementData?.is_custom_cancel_message_active) {
          setCancellationStep(CancellationSteps.CUSTOM_MESSAGE);
        } else {
          setLoading(LoadingTypes.BUTTON_LOADING);
          onCancel(cancellationReasonsForm);
        }
      },
      actionButtonDisabled: !cancellationReasonsForm.reasons.length,
      component: SelectReasons,
    },
    [CancellationSteps.CUSTOM_MESSAGE]: {
      title: t('cancel_modal_title_1'),
      onAction: () => {
        setLoading(LoadingTypes.BUTTON_LOADING);
        onCancel(cancelationManagementData?.is_cancellation_reason_active ? cancellationReasonsForm : undefined);
      },
      component: CustomMessageStep,
    },
  };

  return {
    currentStepConfig: cancellationStepConfigs[cancellationStep],
    cancelationManagementData,
    cancellationReasonsForm,
    setCancellationReasonsForm,
    loading,
  };
};
