import { useEffect } from 'react';

export const useShowModalOnTop = (ref: React.MutableRefObject<HTMLDivElement | null>, dependencyArr: any[] = []) => {
  useEffect(() => {
    setTimeout(() => {
      if (ref) {
        const modalDialogueContainer = ref.current?.closest('.Polaris-Modal-Dialog__Container') as HTMLDivElement;
        const backdropElement = modalDialogueContainer
          ?.closest('div[data-portal-id]')
          ?.querySelector<HTMLDivElement>('.Polaris-Backdrop');
        if (modalDialogueContainer && backdropElement) {
          backdropElement!.style.zIndex = '800';
          modalDialogueContainer.style.zIndex = '801';
        }
      }
    }, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencyArr]);
};
