import { Action, Reducer } from 'redux';
import { ToastActionTypes } from 'states/constants';

const initialState = {
  actionContent: '',
  actionHandler: () => {},
  duration: 5000,
  error: false,
  message: 'Successful',
  show: false,
};

export type ToastAction = Action<ToastActionTypes> & { payload: Partial<typeof initialState> & { message: string } };

export const toastReducer: Reducer<typeof initialState, ToastAction> = (state = initialState, action) => {
  switch (action?.type) {
    case ToastActionTypes.SHOW_TOAST:
      return { ...initialState, ...action?.payload };
    case ToastActionTypes.DISMISS_TOAST:
      return { ...initialState, show: false };
    default:
      return state;
  }
};
