import { Modal } from '@shopify/polaris';
import { PATHS } from 'config/constants';
import { useHistory } from 'react-router-dom';
import { ModalProps } from 'types/general';

export const LogoutModal = ({ isOpen, onClose }: ModalProps) => {
  const history = useHistory();

  const onLogout = () => {
    localStorage.removeItem('loggedInAsUserToken');
    history.push(PATHS.LOGIN);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title="Log out?"
      primaryAction={{ content: 'Logout', onAction: onLogout }}
      secondaryActions={[{ content: 'Cancel', onAction: onClose }]}
    >
      <Modal.Section>
        <p>Are you sure you want to log out?</p>
      </Modal.Section>
    </Modal>
  );
};
