import { Spinner } from '@shopify/polaris';

type Props = { height?: string | number; size?: 'small' | 'large' };

const Loader = ({ height = 40, size }: Props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height }}>
      <Spinner size={size} />
    </div>
  );
};

export default Loader;
