import { api } from 'api/index';
import { Status } from 'types/SubscriptionContract';

export const getSubscriptionContractStatusUpdateHandler = (status: Status) => {
  switch (status) {
    case Status.CANCELLED:
      return api.cancelSubscriptionContract;
    case Status.ACTIVE:
      return api.resumeSubscriptionContract;

    case Status.PAUSED:
      return api.pauseSubscriptionContract;
    default:
      return undefined;
  }
};
