import React, { Fragment } from 'react';
import { Modal, Checkbox, Thumbnail, Text, InlineStack, RadioButton, Box, BlockStack } from '@shopify/polaris';

import productPlaceholder from 'assets/images/product-placeholder.jpg';

import { Product } from 'types/Product';

type Props = {
  product: Product;
  selectedProductIds: number[];
  selectedVariantIds: number[];
  renderProductVariants?: boolean;
  handleProductCheck: (isChecked: boolean, id: string) => void;
  handleVariantCheck: (isChecked: boolean, variantId: string, productId: string) => void;
  isSingleSelection?: boolean;
};

const ProductRow = ({
  product,
  selectedProductIds,
  selectedVariantIds,
  renderProductVariants = true,
  handleProductCheck,
  handleVariantCheck,
  isSingleSelection,
}: Props) => {
  return (
    <Fragment>
      {!isSingleSelection && (
        <div className="product-row">
          <Modal.Section>
            <InlineStack blockAlign="center" gap="200">
              <Checkbox
                label={product?.title}
                labelHidden
                checked={
                  selectedProductIds?.includes(product?.id) ||
                  product?.variants?.some((variant) => selectedVariantIds?.includes(variant?.id))
                }
                onChange={handleProductCheck}
                id={String(product?.id)}
              />
              <Thumbnail alt="product" size="small" source={product?.image_src ?? productPlaceholder} />
              <Text as="span" variant="bodyMd">
                {product?.title}
              </Text>
            </InlineStack>
          </Modal.Section>
        </div>
      )}

      {(product?.variants?.length > 1 || isSingleSelection) &&
        renderProductVariants &&
        product?.variants?.map((variant) => {
          const isSelected = selectedProductIds?.includes(product?.id) || selectedVariantIds?.includes(variant?.id);
          return (
            <div className="product-row">
              <Modal.Section key={variant?.id}>
                <div
                  style={{ paddingLeft: isSingleSelection ? 0 : 35 }}
                  onClick={() =>
                    handleVariantCheck(isSingleSelection || !isSelected, String(variant.id), String(product?.id))
                  }
                >
                  <InlineStack align="space-between">
                    <InlineStack blockAlign="center">
                      {isSingleSelection ? (
                        <Box paddingInlineEnd="400">
                          <RadioButton label labelHidden checked={isSelected} />
                        </Box>
                      ) : (
                        <Checkbox
                          label={`${variant?.title} ${variant?.sku ? `(${variant?.sku})` : ''}`}
                          checked={isSelected}
                          id={String(variant?.id)}
                        />
                      )}
                      {isSingleSelection && (
                        <InlineStack gap="300">
                          <Thumbnail
                            alt="product"
                            size="small"
                            source={variant.image_src || product?.image_src || productPlaceholder}
                          />
                          <BlockStack>
                            <Text as="h6" variant="bodyLg" fontWeight="semibold">
                              {product.title}
                            </Text>
                            <Text as="h6" variant="bodyMd" tone="subdued">
                              {variant.title}
                            </Text>
                          </BlockStack>
                        </InlineStack>
                      )}
                    </InlineStack>
                    <Text as="span" variant="bodyMd">
                      US${variant?.price}
                    </Text>
                  </InlineStack>
                </div>
              </Modal.Section>
            </div>
          );
        })}
    </Fragment>
  );
};

export default ProductRow;
