import { t } from 'react-simple-multi-language';
import { SellingPlanInterval } from 'types/SellingPlan';
import { calculatePrepaidIntervalCount } from './calculatePrepaidIntervalCount';

const intervalTypeContentKeys = {
  [SellingPlanInterval.DAY]: {
    basicSubscription: 'every_days',
    prepaidSubscription: 'prepaid_days',
  },
  [SellingPlanInterval.WEEK]: {
    basicSubscription: 'every_weeks',
    prepaidSubscription: 'prepaid_weeks',
  },
  [SellingPlanInterval.MONTH]: {
    basicSubscription: 'every_months',
    prepaidSubscription: 'prepaid_months',
  },
  [SellingPlanInterval.YEAR]: {
    basicSubscription: 'every_years',
    prepaidSubscription: 'prepaid_years',
  },
};

export const intervalFormatter = (
  interval: SellingPlanInterval,
  intervalCount: number,
  prePaidBillingCount?: number
) => {
  let sellingPlanName = '';
  const intervalCountStr = String(intervalCount);

  sellingPlanName = t(intervalTypeContentKeys[interval].basicSubscription, { interval_count: intervalCountStr });

  const prepaidIntervalCount = calculatePrepaidIntervalCount(intervalCount, prePaidBillingCount);

  if (prepaidIntervalCount) {
    sellingPlanName += `, ${t(intervalTypeContentKeys[interval].prepaidSubscription, {
      interval_count: String(prepaidIntervalCount),
    })}`;
  }

  return sellingPlanName;
};
