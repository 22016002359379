import { AdaptedSellingPlan, RawSellingPlan } from 'types/AdaptedSellingPlan';
import { SellingPlanAnchor } from 'types/SellingPlan';

export class FrequencyDataAdapter {
  static createBillingPolicy = (sellingPlan: RawSellingPlan, anchors?: SellingPlanAnchor[]) => {
    const billing_policy: AdaptedSellingPlan['billing_policy'] = {
      recurring: {
        interval: sellingPlan?.deliveryIntervals,
        interval_count: Number(sellingPlan.prePaidBillingCount)
          ? Number(sellingPlan.prePaidBillingCount) * parseInt(sellingPlan?.intervals)
          : parseInt(sellingPlan?.intervals),
      },
    };

    const maxBillingsConstraint = Number(sellingPlan.maxBillingsConstraint);
    const minBillingsConstraint = Number(sellingPlan.minBillingsConstraint);

    billing_policy.recurring.maxCycles = maxBillingsConstraint || null;
    billing_policy.recurring.minCycles = minBillingsConstraint || null;

    billing_policy.recurring.anchors = anchors || [];
    return billing_policy;
  };

  static createDeliveryPolicy = (sellingPlan: RawSellingPlan, anchors?: SellingPlanAnchor[]) => {
    const deliveryPolicy: AdaptedSellingPlan['delivery_policy'] = {
      recurring: {
        interval: sellingPlan?.deliveryIntervals,
        interval_count: parseInt(sellingPlan?.intervals),
      },
    };

    deliveryPolicy.recurring.anchors = anchors || [];

    deliveryPolicy.recurring.cutoff =
      sellingPlan.deliveryCutoff && anchors?.length ? Number(sellingPlan.deliveryCutoff) : null;

    return deliveryPolicy;
  };
}
