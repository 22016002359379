import { ChooseStore } from './ChooseStore';
import { LoginForm } from './LoginForm';
import { VerifyForm } from './VerifyForm';

export const useLoginSteps = ({ email }: { email: string }) => {
  return [
    {
      id: 0,
      title: 'Log in to portal',
      subtitle: 'Please enter your email address to receive a verification code.',
      component: LoginForm,
    },
    {
      id: 1,
      title: 'Authenticate your account',
      subtitle: `Please enter 6 digits code sent to ${email}`,
      component: VerifyForm,
    },
    {
      id: 2,
      title: 'Choose a store',
      subtitle: undefined,
      component: ChooseStore,
    },
  ];
};
