import { RawSellingPlan } from './AdaptedSellingPlan';

import { Address, Customer } from 'types/Customer';
import { CustomerPaymentMethod } from 'types/CustomerPaymentMethod';
import { Order } from 'types/Order';
import { SellingPlanAnchor, SellingPlanInterval } from 'types/SellingPlan';
import { SellingPlanPricingAdjustmentType, SellingPlanPricingAdjustmentValue } from 'types/SellingPlan';
import { PaginatedRequestPayload } from 'types/general';

export interface SubscriptionContract {
  app: {
    id: string;
    title: string;
  };
  billing_policy: SubscriptionBillingPolicy;
  created_at: Date;
  currency_code: string;
  customer: Customer;
  customer_payment_method: CustomerPaymentMethod;
  delivery_method: {
    address?: Address;
    __typename: SubscriptionDeliveryMethod;
  };
  delivery_policy: SubscriptionDeliveryPolicy;
  delivery_price: Price;
  human_readable_created_at: string;
  id: number;
  initial_price_amount: number;
  last_payment_status: LastPaymentStatus | null;
  line_count: number;
  lines: SubscriptionContractLine[];
  next_billing_date: Date;
  next_cycle_index: number;
  shop: number;
  status: Status;
  last_billing_error_code: string | null;
  last_billing_error_message: string | null;
  last_billing_created_at: string | null;
  note: string | null;
  order_discounts: ContractDiscount[];
  shipping_discounts: ContractDiscount[];
  orders: Order[];
}

export interface SubscriptionBillingPolicy {
  anchors?: SellingPlanAnchor[];
  interval: SellingPlanInterval;
  interval_count: number;
  max_cycles: number | null;
  min_cycles: number | null;
}

export enum SubscriptionDeliveryMethod {
  LOCAL = 'SubscriptionDeliveryMethodLocalDelivery',
  PICKUP = 'SubscriptionDeliveryMethodPickup',
  SHIPPING = 'SubscriptionDeliveryMethodShipping',
}

export interface SubscriptionDeliveryPolicy {
  interval: SellingPlanInterval;
  interval_count: number;
}

export type Price = {
  amount: string;
  currency_code: string;
};

export enum LastPaymentStatus {
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export type ProductLineDiscount = {
  applied_amount: {
    amount: string;
    currency_code: string;
  };
  manual_discount: {
    id: string;
    recurring_cycle_limit: number;
    title: string;
    usage_count: number;
    discount_type: string;
    discount_percentage: number;
    active: boolean;
  };
};

export interface SubscriptionContractLine {
  current_price: Price;
  id: string;
  line_discounted_price: Price;
  product_discounts: ProductLineDiscount[];
  pricing_policy?: {
    base_price: Price;
    cycle_discounts: CycleDiscount[];
  };
  product_id: number;
  quantity: number;
  selling_plan_id: number;
  selling_plan_name: string;
  title: string;
  variant_id: number;
  variant_image_src: string;
  variant_title: string;
}

export interface CycleDiscount {
  adjustment_type: SellingPlanPricingAdjustmentType;
  adjustment_value: SellingPlanPricingAdjustmentValue;
  after_cycle: number;
  computed_price: Price;
}

export enum Status {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  FAILED = 'FAILED',
  PAUSED = 'PAUSED',
}

export type SubscriptionContractsRequestPayload = PaginatedRequestPayload & {
  ordering?: string;
  search?: string;
  status?: Status;
};

export interface UpdateSubscriptionLine {
  id: string;
  quantity: number;
}

export interface NewSubscriptionLine {
  product_variant_id: number;
  quantity: number;
}
export interface UpdateSubscriptionContractPayload {
  delivery_method?: {
    shipping: {
      address: Address;
    };
  };
  lines_to_add?: NewSubscriptionLine[];
  lines_to_remove?: string[];
  lines_to_update?: UpdateSubscriptionLine[];
  delivery_policy?: SubscriptionDeliveryPolicy;
  billing_policy?: SubscriptionBillingPolicy;
  delivery_price?: number;
  note?: string;
}

export type ContractSellingPlan = Required<
  Pick<RawSellingPlan, 'prePaidBillingCount' | 'intervals' | 'deliveryIntervals'>
>;

export enum DiscountType {
  PERCENTAGE = 'SubscriptionDiscountPercentageValue',
  FIXED = 'SubscriptionDiscountFixedAmountValue',
}

type BaseContractDiscountDiscount = {
  id: string;
  recurring_cycle_limit: number;
  title: string;
  usage_count: number;
  active: boolean;
};

export type PercentageContractDiscount = BaseContractDiscountDiscount & {
  discount_type: DiscountType.PERCENTAGE;
  discount_percentage: number;
};

export type FixedContractDiscount = BaseContractDiscountDiscount & {
  discount_type: DiscountType.FIXED;
  discount_amount: {
    amount: string;
    currency_code: string;
  };
};

export type ContractDiscount = PercentageContractDiscount | FixedContractDiscount;
