import { t } from 'react-simple-multi-language';
import { Status } from 'types/SubscriptionContract';
import { Status as PolarisStatus } from 'types/Polaris';

type Return = {
  title: string;
  style: PolarisStatus;
};

export const useSubscriptionStatus = (status: Status): Return => {
  switch (status) {
    case Status.ACTIVE:
      return {
        title: t('status_active'),
        style: 'success',
      };
    case Status.PAUSED:
      return {
        title: t('status_paused'),
        style: 'warning',
      };
    case Status.CANCELLED:
      return {
        title: t('status_canceled'),
        style: 'info',
      };
    case Status.FAILED:
      return {
        title: t('status_failed'),
        style: 'critical',
      };
    case Status.EXPIRED:
      return {
        title: t('status_expired'),
        style: 'critical',
      };
    default:
      return {
        title: 'Unknown',
        style: 'info',
      };
  }
};
