import { Reducer, combineReducers } from 'redux';

import { toastReducer } from './reducers/toast';

export type RootState = { toast: ReturnType<typeof toastReducer> };

export const rootReducer: Reducer<RootState, any> = (state, action) => {
  return combineReducers({
    toast: toastReducer,
  })(state, action);
};
