export const constraints = {
  sampleEmailField: {
    presence: {
      allowEmpty: false,
      message: "can not be empty"
    },
    format: {
      pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: '^Please enter a valid email address'
    },
  },
  sampleTextField: {
    presence: {
      allowEmpty: false,
      message: "can not be empty"
    },
    length: {
      maximum: 128,
      message: "must be less than 128 characters"
    }
  },
  sampleNumberField: {
    numericality: {
      onlyInteger: true,
      greaterThan: 1,
      message: '^Must be more than 1',
    },
  },
};
