import { t } from 'react-simple-multi-language';
import { OrderFinancialStatus } from 'types/Order';
import { Progress, Status } from 'types/Polaris';

type Return = {
  title: string;
  style: Status;
  progress: Progress;
};

export const getOrderHistoryStatus = (status: OrderFinancialStatus): Return => {
  switch (status) {
    case 'PAID':
      return {
        title: t('order_status_paid'),
        style: 'info',
        progress: 'complete',
      };
    case 'AUTHORIZED':
      return {
        title: t('order_status_authorized'),
        style: 'info',
        progress: 'complete',
      };
    case 'EXPIRED':
      return {
        title: t('order_status_expired'),
        style: 'info',
        progress: 'complete',
      };
    case 'PARTIALLY_PAID':
      return {
        title: t('order_status_partially_paid'),
        style: 'info',
        progress: 'complete',
      };
    case 'PARTIALLY_REFUNDED':
      return {
        title: t('order_status_partially_refunded'),
        style: 'info',
        progress: 'complete',
      };
    case 'PENDING':
      return {
        title: t('order_status_pending'),
        style: 'info',
        progress: 'complete',
      };
    case 'REFUNDED':
      return {
        title: t('order_status_refunded'),
        style: 'info',
        progress: 'complete',
      };
    case 'VOIDED':
      return {
        title: 'Voided',
        style: 'info',
        progress: 'complete',
      };
    default:
      return {
        title: 'Unknown',
        style: 'info',
        progress: 'incomplete',
      };
  }
};
