import { LoginWithAuthParamsResponse } from './../types/Authentication';
import { AxiosResponse } from 'axios';
import { PATHS } from 'config/constants';
import { LoginWithAuthParamsRequest } from 'types/Authentication';

export class LoginWithAuthParams {
  url = new URL(window.location.href);
  authParamsKeys = ['authPortalToken', 'customerId', 'timestamp', 'shop'] as const;
  isLoading = false;

  private redirect = (path: string) => {
    window.open(window.location.origin + path, '_self');
  };

  protected isAuthParamsExist() {
    return this.authParamsKeys.every((key) => this.url.searchParams.get(key));
  }

  private getAuthParams() {
    return this.authParamsKeys.reduce((acc, key) => {
      const value = this.url.searchParams.get(key)!;
      acc[key] = value;
      return acc;
    }, {} as Record<(typeof this.authParamsKeys)[number], string>);
  }

  protected async loginWithAuthParams(
    getAuthTokenFn: (data: LoginWithAuthParamsRequest) => Promise<AxiosResponse<LoginWithAuthParamsResponse>>
  ) {
    if (this.isLoading) return;
    if (!this.isAuthParamsExist()) return this.redirect(PATHS.LOGIN);

    this.isLoading = true;

    const params = this.getAuthParams();

    try {
      const { data } = await getAuthTokenFn({
        token: params.authPortalToken,
        customer_id: Number(params.customerId),
        timestamp: Number(params.timestamp),
        shopify_domain: params.shop,
      });

      localStorage.setItem('loggedInAsUserToken', data.access_token);
      this.redirect(PATHS.SUBSCRIPTIONS);
      this.isLoading = false;
    } catch (error) {
      this.redirect(PATHS.LOGIN);
      this.isLoading = false;
    }
  }
}
