export type CancellationReason = {
  id: number;
  title: string;
};

export type CancellationReasonsList = {
  is_cancellation_reason_active: boolean;
  cancellation_reasons: CancellationReason[];
};

export type CancellationMessage = {
  is_custom_cancel_message_active: boolean;
  custom_cancel_message: null | string;
};

export type CancellationManagement = CancellationReasonsList & CancellationMessage;

export type CancellationReasonsData = {
  reasons: {
    id: number;
  }[];
  customer_reason: string;
};

export enum CancellationSteps {
  CONFORMATION_MESSAGE = 'CONFORMATION_MESSAGE',
  SELECT_REASON = 'SELECT_REASON',
  CUSTOM_MESSAGE = 'CUSTOM_MESSAGE',
}

export type CancellationStepProps = {
  cancellationData: CancellationManagement | null;
  cancelationReasonForm: CancellationReasonsData;
  setCancellationReasonForm: React.Dispatch<React.SetStateAction<CancellationReasonsData>>;
};

export type CancellationStepConfigs = {
  [key in CancellationSteps]: {
    title: string;
    onAction: () => void;
    actionButtonDisabled?: boolean;
    component: React.FC<CancellationStepProps>;
  };
};
