import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { BlockStack, Box, InlineStack, Link, Text, Thumbnail } from '@shopify/polaris';

import productImagePlaceHolder from 'images/product-placeholder.jpg';

import { ThumbnailSize } from 'types/Polaris';

type Alignment = 'start' | 'center' | 'end' | 'baseline' | 'stretch';

type ResouceLinkPorps = {
  url: string;
  externalUrl?: boolean;
  children: React.ReactNode;
};

const ResouceLink = ({ url, externalUrl, children }: ResouceLinkPorps) => (
  <Link
    onClick={() => {
      if (externalUrl) window.open(url, '_blank');
    }}
    url={url}
    external={externalUrl}
    removeUnderline
    monochrome
  >
    {children}
  </Link>
);

type ResouceItemPorps = {
  children?: React.ReactNode;
  resource: {
    image?: string | null;
    title?: string;
    subtitle?: string | ReactNode;
    thumbnailSize?: ThumbnailSize;
    url?: string;
    alignment?: Alignment;
    countNumber?: number;
  };
};

export const ResourceInfo = ({
  children,
  resource: { image, title, subtitle, alignment, countNumber, thumbnailSize = 'medium', url },
}: ResouceItemPorps) => {
  const TitleWrapper = url ? ResouceLink : React.Fragment;
  return (
    <InlineStack wrap={false} blockAlign={alignment || 'center'} gap="300">
      <Box paddingBlockStart="150">
        <InlineStack gap="500">
          {countNumber && (
            <Text as="span" variant="bodyLg">
              {countNumber}
            </Text>
          )}
          <Thumbnail source={image ?? productImagePlaceHolder} alt={title || ''} size={thumbnailSize} />
        </InlineStack>
      </Box>
      <div style={{ width: 200 }}>
        <BlockStack gap="025">
          {title && (
            <h4 title={title} style={{ color: url ? '#2c6ecb' : '#000', fontSize: '15px' }}>
              <TitleWrapper url={url!}>
                <Text variant="bodyLg" as="span" fontWeight="medium">
                  {title}
                </Text>
              </TitleWrapper>
            </h4>
          )}
          {Boolean(subtitle) && (
            <Text as="span" variant="bodyMd" tone={'subdued'}>
              {subtitle}
            </Text>
          )}
          {children}
        </BlockStack>
      </div>
    </InlineStack>
  );
};
