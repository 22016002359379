interface Options {
  startsFrom: number;
  endsUntil: number;
  IndexedLabel?: { [key: number]: string };
  ignoreValues?: number[];
  generateLabel: (iterate: number) => string;
}
type ReturnType = { label: string; value: string }[];
const createIterableSelectOptions = ({
  endsUntil,
  generateLabel,
  startsFrom,
  IndexedLabel = {},
  ignoreValues = [],
}: Options): ReturnType => {
  const config: ReturnType = [];
  for (let index = startsFrom; index < endsUntil + 1; index++) {
    if (!ignoreValues.includes(index)) {
      config.push({
        label: IndexedLabel[index] || generateLabel(index),
        value: String(index),
      });
    }
  }

  return config;
};

export default createIterableSelectOptions;
