import { api } from 'api/index';
import { useEffect, useState } from 'react';

const setFavIcon = (icon: string) => {
  const favIconsElements = document.head.querySelectorAll<HTMLLinkElement>(
    "link[rel='apple-touch-icon'],link[rel='icon']"
  );
  favIconsElements.forEach((el) => {
    el.href = icon;
  });
};

export const useLoadShopIcon = (): [boolean, string | undefined] => {
  const [loading, setLoading] = useState(true);
  const [logo, setLogo] = useState<string | undefined>(undefined);

  useEffect(() => {
    api
      .getShopBrandInformation()
      .then((res) => {
        const { custom_logo, icon, logo } = res.data;

        if (icon) setFavIcon(icon);
        setLogo(custom_logo || logo);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return [loading, logo];
};
