import { TextContainer } from '@shopify/polaris';
import { CancellationStepProps } from 'types/CancelationManagement';
import parse from 'html-react-parser';

export const CustomMessageStep = ({ cancellationData }: CancellationStepProps) => {
  return (
    <TextContainer>
      <p className="ck-editor-parser">{parse(cancellationData!.custom_cancel_message!)}</p>
    </TextContainer>
  );
};
