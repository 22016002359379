import { useState, useEffect } from 'react';

export interface FetchDataConfigType<Data> {
  initialData?: any;
  responseSelector?: (d: any) => Data;
  onError?: (er: any) => void;
}

export type FetchDataReturnType<Data> = [Data, boolean, React.Dispatch<React.SetStateAction<Data>>];

export const useFetchData = <Data = any>(
  request: () => Promise<any>,
  { initialData = {}, responseSelector, onError }: FetchDataConfigType<Data> = {}
): FetchDataReturnType<Data> => {
  const [data, setData] = useState<Data>(initialData);
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    request()
      .then((resp) => {
        setData(responseSelector ? responseSelector(resp.data) : resp.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        onError && onError(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [data, Loading, setData];
};
