import React, { useCallback, useState } from 'react';
import { Badge, BlockStack, Button, Collapsible, DataTable, Icon, InlineStack, Text } from '@shopify/polaris';
import { ChevronDownIcon, ChevronUpIcon } from '@shopify/polaris-icons';

// import { getFulfillmentStatus } from './getFulfillmentStatus';
import { getOrderHistoryStatus } from './getOrderHistoryStatus';
import { convertToLocaleDatetime } from 'utils/dateTimeUtils';
import TableEmptyState from '@/shared/table/TableEmptyState';
import { t } from 'react-simple-multi-language';
import { Order } from 'types/Order';
import { FeaturedCard } from '@/shared/FeaturedCard/FeaturedCard';
import { FeaturedCardSection } from '@/shared/FeaturedCard/FeaturedCardSection';

type Props = {
  orders: Order[];
};

const OrderHistoryTable = ({ orders }: Props) => {
  const [openTable, setOpenTable] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const handleToggleTable = useCallback(() => setOpenTable((openTable) => !openTable), []);
  const handleToggleShowMore = useCallback(() => setShowMore((showMore) => !showMore), []);

  const rows = orders?.map((order) => {
    const orderStatus = getOrderHistoryStatus(order?.display_financial_status);

    return [
      <BlockStack gap="150">
        <p>{`${order?.id}`}</p>
        {order.name && <p>{`${order.name}`}</p>}
      </BlockStack>,
      convertToLocaleDatetime(order?.created_at),
      <Badge tone={orderStatus?.style} progress={orderStatus?.progress}>
        {orderStatus?.title}
      </Badge>,
    ];
  });

  const orderHistoryTableMarkup =
    rows?.length === 0 ? (
      <TableEmptyState />
    ) : (
      <DataTable
        columnContentTypes={['text', 'text', 'text']}
        headings={[t('order_id'), t('order_date'), t('order_status')]}
        rows={showMore ? rows : rows?.slice(0, 5)}
        verticalAlign="middle"
      />
    );

  return (
    <div className="subscription-orders-table">
      <FeaturedCard>
        <FeaturedCardSection>
          <InlineStack blockAlign="center" align="space-between">
            <Text as="h6" variant="headingMd">
              {t('order_history')}
            </Text>
            <Button variant="monochromePlain" onClick={handleToggleTable}>
              {(<Icon source={openTable ? ChevronUpIcon : ChevronDownIcon} />) as unknown as string}
            </Button>
          </InlineStack>
        </FeaturedCardSection>

        <Collapsible
          open={openTable}
          id="orders-history-table-collapsible"
          transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
        >
          <div style={{ paddingBottom: 25 }}>{orderHistoryTableMarkup}</div>

          {rows?.length > 5 && (
            <div style={{ borderTop: '1px solid rgb(225, 227, 229)' }}>
              <FeaturedCardSection>
                <InlineStack align="center" blockAlign="center">
                  <Button variant="monochromePlain" onClick={handleToggleShowMore}>
                    {
                      (
                        <InlineStack blockAlign="center" gap="150">
                          <span>{showMore ? 'Show Less' : t('show_more')}</span>
                          <Icon source={openTable ? ChevronUpIcon : ChevronDownIcon} />
                        </InlineStack>
                      ) as unknown as string
                    }
                  </Button>
                </InlineStack>
              </FeaturedCardSection>
            </div>
          )}
        </Collapsible>
      </FeaturedCard>
    </div>
  );
};

export default OrderHistoryTable;
