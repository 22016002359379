import React, { useCallback, useState } from 'react';
import { Modal, TextContainer } from '@shopify/polaris';
import { useDispatch } from 'react-redux';

import { api } from 'api/index';
import { showToast } from 'states/actions/toast';

import { t } from 'react-simple-multi-language';
import { ModalProps } from 'types/general';

type Props = {
  subscriptionId: number;
  updateNextBillingDate: (date: Date) => void;
} & ModalProps;

const SkipUpcomingOrderModal = ({ isOpen, subscriptionId, onClose, updateNextBillingDate }: Props) => {
  const dispatch = useDispatch();

  const [skipUpcomingOrderLoading, setSkipUpcomingOrderLoading] = useState(false);

  const handleSkipUpcomingOrder = useCallback(() => {
    setSkipUpcomingOrderLoading(true);

    api
      .skipBilling(subscriptionId)
      .then((resp) => {
        showToast(dispatch)(t('toast_successful'));
        updateNextBillingDate(resp?.data?.next_billing_date);
        onClose();
        setSkipUpcomingOrderLoading(false);
      })
      .catch((err) => {
        showToast(dispatch)(err.message, { error: true });

        setSkipUpcomingOrderLoading(false);
      });
  }, [subscriptionId, dispatch, updateNextBillingDate, onClose]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={t('skip_delivery')}
      primaryAction={{
        content: t('button_skip'),
        onAction: handleSkipUpcomingOrder,
        destructive: true,
        outline: true,
        loading: skipUpcomingOrderLoading,
      }}
      secondaryActions={[{ content: t('button_cancel'), onAction: onClose }]}
    >
      <Modal.Section>
        <TextContainer>
          <p>{t('skip_modal_desc')}</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );
};

export default SkipUpcomingOrderModal;
