import { Switch, Route, withRouter } from 'react-router-dom';

import MainLayout from '@/layouts/MainLayout';
import { PATHS } from 'config/constants';

import Subscriptions from './pages/Subscriptions';
import SingleSubscription from './pages/SingleSubscription';
import NotFound from './pages/NotFound';
import { Login } from '@/auth/Login';

const Router = () => {
  return (
    <Switch>
      <Route exact path={PATHS.LOGIN} component={Login} />

      <MainLayout exact path={PATHS.SUBSCRIPTIONS} component={Subscriptions} />
      <MainLayout exact path={`${PATHS.SUBSCRIPTIONS}:id`} component={SingleSubscription} />

      <Route component={NotFound} />
    </Switch>
  );
};

export default withRouter(Router);
