export const useFrequencyIntervalInfo = (frequency: any) => {
  const deliveryIntervals = frequency?.delivery_policy?.recurring
    ? frequency?.delivery_policy?.recurring
    : frequency?.delivery_policy ?? {};

  const billingPolicy = frequency?.billing_policy?.recurring
    ? frequency?.billing_policy?.recurring
    : frequency?.billing_policy ?? {};

  const anchors = deliveryIntervals?.anchors || [];

  return {
    ...deliveryIntervals,
    billingPolicyIntervalCount: billingPolicy.interval_count
      ? String(billingPolicy.interval_count / deliveryIntervals.interval_count)
      : '1',
    minBillingsConstraint: String(billingPolicy.minCycles || billingPolicy.min_cycles || '0'),
    maxBillingsConstraint: String(billingPolicy.maxCycles || billingPolicy.max_cycles || '0'),
    deliveryDay: anchors.length ? String(anchors[0].day) : null,
    deliveryMonth: anchors.length ? String(anchors[0].month) : null,
    deliveryCutoff: deliveryIntervals?.cutoff ? String(deliveryIntervals?.cutoff) : null,
  };
};
