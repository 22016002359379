import { Toast } from '@shopify/polaris';

export type ToastProps = {
  actionContent: string;
  actionHandler: () => void;
  duration?: number;
  error?: boolean;
  handleDismiss: () => void;
  message: string;
};

const ToastMessage = ({
  actionContent = '',
  actionHandler = () => {},
  duration = 5000,
  error,
  handleDismiss,
  message,
}: ToastProps) => {
  const hasAction = Boolean(actionContent);

  return (
    <Toast
      content={message}
      duration={hasAction ? 10000 : duration}
      error={error}
      onDismiss={handleDismiss}
      action={
        hasAction
          ? {
              content: actionContent,
              onAction: actionHandler,
            }
          : undefined
      }
    />
  );
};

export default ToastMessage;
