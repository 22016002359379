import React, { useCallback, useState } from 'react';
import { FormLayout, Modal, TextField } from '@shopify/polaris';

import { api } from 'api/index';
import { formValidation } from 'utils/formValidation';
import { checkFormDisablity } from 'utils/checkFormDisability';
import { t } from 'react-simple-multi-language';
import { Address } from 'types/Customer';
import { ModalProps } from 'types/general';
import { FormErrorType } from 'types/TypeUtils';

type Props = ModalProps & {
  deliveryAddress: Address | undefined;
  subscriptionId: number;
  handleUpdateDeliveryAddress: (customerInfo: Partial<Address>) => void;
};

const DeliveryAddressModal = ({
  isOpen,
  onClose,
  deliveryAddress,
  subscriptionId,
  handleUpdateDeliveryAddress,
}: Props) => {
  const [fields, setFields] = useState(deliveryAddress || ({} as Partial<Address>));
  const [errors, setErrors] = useState<FormErrorType<Address>>({});

  const handleUpdateFields = useCallback((value: string, key: keyof Partial<Address>) => {
    setErrors((errors) => {
      return { ...errors, [key]: '' };
    });
    setFields((fields) => {
      return { ...fields, [key]: value };
    });
  }, []);

  const handleValidation = useCallback(
    (key: keyof Partial<Address>) => {
      const err = formValidation(key, fields[key]);
      setErrors((errors) => {
        return { ...errors, [key]: err };
      });
    },
    [fields]
  );

  const [submitLoad, setSubmitLoad] = useState(false);

  const handleSave = useCallback(() => {
    const fieldsKeyToValidate = ['first_name', 'last_name', 'city', 'country', 'zip', 'address1'];
    const fieldsToValidate = Object.entries(fields)
      .filter(([key, value]) => fieldsKeyToValidate?.includes(key))
      .reduce<Partial<Address>>((prev, [key, value]) => {
        prev[key as keyof Partial<Address>] = value as any;
        return prev;
      }, {});
    const hasErrors = checkFormDisablity(fieldsToValidate);
    if (hasErrors) return;

    setSubmitLoad(true);

    const data = {
      delivery_method: {
        shipping: {
          address: Object.entries(fields)
            ?.filter(([key, value]) => value)
            ?.reduce<Partial<Address>>((prev, [key, value]) => {
              prev[key as keyof Partial<Address>] = value as any;
              return prev;
            }, {}),
        },
      },
    };

    api
      .updateSubscriptionContract(subscriptionId, data)
      .then((resp) => {
        handleUpdateDeliveryAddress(fields);
        setSubmitLoad(false);
        onClose();
      })
      .catch((err) => setSubmitLoad(false));
  }, [fields, onClose, handleUpdateDeliveryAddress, subscriptionId]);

  const closeModal = useCallback(() => {
    setFields(deliveryAddress as Address);
    onClose();
  }, [deliveryAddress, onClose]);

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      title={t('edit_shipping_address')}
      primaryAction={{ content: t('save'), onAction: handleSave, loading: submitLoad }}
      secondaryActions={[
        {
          content: t('button_cancel'),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              label={t('first_name')}
              autoComplete="off"
              id="first_name"
              value={fields?.first_name}
              onChange={handleUpdateFields}
              error={errors?.first_name}
              onBlur={() => handleValidation('first_name')}
            />
            <TextField
              label={t('last_name')}
              autoComplete="off"
              id="last_name"
              value={fields?.last_name}
              onChange={handleUpdateFields}
              error={errors?.last_name}
              onBlur={() => handleValidation('last_name')}
            />
          </FormLayout.Group>
          <TextField
            label={t('address')}
            autoComplete="off"
            id="address1"
            value={fields?.address1}
            onChange={handleUpdateFields}
            error={errors?.address1}
            onBlur={() => handleValidation('address1')}
          />
          <TextField
            label={t('address_detail')}
            autoComplete="off"
            id="address2"
            value={fields?.address2}
            onChange={handleUpdateFields}
          />
          <TextField
            label={t('city')}
            autoComplete="off"
            id="city"
            value={fields?.city}
            onChange={handleUpdateFields}
            error={errors?.city}
            onBlur={() => handleValidation('city')}
          />
          <FormLayout.Group condensed>
            <TextField
              label={t('country')}
              autoComplete="off"
              id="country"
              value={fields?.country}
              onChange={handleUpdateFields}
              error={errors?.country}
              onBlur={() => handleValidation('country')}
            />
            <TextField
              label={t('province')}
              autoComplete="off"
              id="province"
              value={fields?.province}
              onChange={handleUpdateFields}
            />
            <TextField
              label={t('postal_code')}
              autoComplete="off"
              id="zip"
              value={fields?.zip}
              onChange={handleUpdateFields}
              error={errors?.zip}
              onBlur={() => handleValidation('zip')}
            />
          </FormLayout.Group>
          <TextField
            label={t('phone')}
            autoComplete="off"
            id="phone"
            value={String(fields?.phone)}
            onChange={handleUpdateFields}
            // connectedRight={
            //   <TextField
            //     label="Country flag"
            //     labelHidden
            //     autoComplete="off"
            //     id="flag"
            //     value={fields?.flag}
            //     onChange={handleUpdateFields}
            //   />
            // }
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
};

export default DeliveryAddressModal;
