import React, { useCallback, useContext, useState } from 'react';
import { Banner, BlockStack, InlineStack, Text } from '@shopify/polaris';

import { convertToLocaleDatetime } from 'utils/dateTimeUtils';
import { intervalFormatter } from 'utils/intervalFormatter';
import { getPriceWithCurrencyCode } from 'utils/currency';
import ContractItemsModal from '@/modals/ContractItemsModal';
import { useTotalPrice } from 'hooks/useTotalPrice';
import { useCurrencyCode } from 'hooks/useCurrencyCode';
import SubscriptionLine from './SubscriptionLine';
import { PermissionsContext } from '../../pages/SingleSubscription';
import { t } from 'react-simple-multi-language';
import { SubscriptionContract } from 'types/SubscriptionContract';
import { DiscountInfo } from 'types/DiscountInfo';
import { FeaturedCard } from '@/shared/FeaturedCard/FeaturedCard';
import { FeaturedCardSection } from '@/shared/FeaturedCard/FeaturedCardSection';

type Props = {
  discountInfo: DiscountInfo | null;
  subscription: SubscriptionContract;
  updateSubscription: React.Dispatch<React.SetStateAction<SubscriptionContract>>;
  handleRemoveItem: (idsToRemove: string[]) => void;
};

const SubscriptionDetails = ({ subscription, discountInfo, updateSubscription, handleRemoveItem }: Props) => {
  const { permissions } = useContext(PermissionsContext);

  const totalPrice = useTotalPrice(subscription);
  const currencyCode = useCurrencyCode(subscription);

  const [openContractItemsModal, setOpenContractItemsModal] = useState(false);

  const toggleContractItemsModal = useCallback(() => {
    setOpenContractItemsModal((openContractItemsModal) => !openContractItemsModal);
  }, []);

  const subscriptionInterval = intervalFormatter(
    subscription?.delivery_policy?.interval,
    subscription?.delivery_policy?.interval_count,
    subscription?.billing_policy?.interval_count / subscription?.delivery_policy?.interval_count
  );

  const firstOrderDate = subscription?.orders?.length
    ? convertToLocaleDatetime(subscription?.orders[0]?.created_at)
    : '--';

  return (
    <div className="subscription-details-card">
      <FeaturedCard
        title={t('subscription_details')}
        sectioned
        actions={
          permissions.change_subscription_interval ||
          permissions.change_quantity ||
          permissions.remove_subscription_line ||
          permissions.add_subscription_line
            ? [
                {
                  content: t('edit'),
                  onAction: toggleContractItemsModal,
                },
              ]
            : undefined
        }
      >
        <FeaturedCardSection>
          <BlockStack gap="400">
            {discountInfo && <Banner tone="success">{t('discount_applied')}</Banner>}
            <BlockStack gap="150">
              <Text as="p" variant="bodyLg">
                {t('first_order', { first_order_date: firstOrderDate })}
              </Text>
              <Text as="h6" variant="headingMd">
                {subscriptionInterval}
              </Text>
            </BlockStack>
          </BlockStack>
        </FeaturedCardSection>

        {subscription?.lines?.map((item) => (
          <SubscriptionLine key={item.id} item={item} discountInfo={discountInfo} />
        ))}

        <FeaturedCardSection>
          <BlockStack gap="150">
            <InlineStack align="space-between">
              <div>
                <p style={{ fontSize: 14 }}>
                  <Text as="span" fontWeight="semibold" variant="bodyMd">
                    {t('total')}
                  </Text>
                </p>
                <p style={{ fontSize: 12 }}>
                  <Text as="span" tone="subdued" variant="bodyMd">
                    {t('tax_included')}
                  </Text>
                </p>
              </div>
              <Text as="span" fontWeight="semibold" variant="bodyMd">
                {getPriceWithCurrencyCode(discountInfo?.total_price || totalPrice?.toFixed(2), currencyCode)}
              </Text>
            </InlineStack>
          </BlockStack>
        </FeaturedCardSection>

        {openContractItemsModal && (
          <ContractItemsModal
            isOpen={true}
            onClose={toggleContractItemsModal}
            items={subscription?.lines}
            subscription={subscription}
            updateSubscription={updateSubscription}
            handleRemoveItem={handleRemoveItem}
          />
        )}
      </FeaturedCard>
    </div>
  );
};

export default SubscriptionDetails;
