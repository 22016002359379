import React, { useCallback, useEffect, useState } from 'react';
import { Page } from '@shopify/polaris';

import SubscriptionCard from '@/subscriptions/SubscriptionCard';
import { api } from 'api/index';
import WholePageLoading from '@/shared/loaders/WholePageLoading';
import { t } from 'react-simple-multi-language';

import { SubscriptionContract } from 'types/SubscriptionContract';
import { purgeObjectFromFalsyValues } from 'utils/purgeObjectFromFalsyValues';

const Subscriptions = () => {
  const [subscriptions, setSubscriptions] = useState<SubscriptionContract[]>([]);
  const [loading, setLoading] = useState(true);

  const [customerName, setCustomerName] = useState('');

  const getData = useCallback(() => {
    const params = {
      limit: 100,
      offset: 0,
    };

    setLoading(true);
    Promise.all([api.getSubscriptionContracts(purgeObjectFromFalsyValues(params, true)), api.getCustomerInfo()])
      .then((resp) => {
        setSubscriptions(resp[0].data?.results);
        setCustomerName(`${resp[1].data.first_name} ${resp[1].data.last_name}`);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  if (loading) return <WholePageLoading />;

  return (
    <div className="subscription-list-page-wrapper">
      <Page title={t('hi_customer', { customer_name: customerName })}>
        {subscriptions.map((subscription) => (
          <SubscriptionCard key={subscription?.id} subscription={subscription} />
        ))}
      </Page>
    </div>
  );
};

export default Subscriptions;
