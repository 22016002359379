import React, { useCallback, useMemo, useState } from 'react';
import { FormLayout, Modal, TextField } from '@shopify/polaris';

import { api } from 'api/index';
import { formValidation } from 'utils/formValidation';
import { checkFormDisablity } from 'utils/checkFormDisability';
import { t } from 'react-simple-multi-language';
import { ModalProps } from 'types/general';
import { Customer } from 'types/Customer';
import { FormErrorType } from 'types/TypeUtils';

type Props = ModalProps & {
  customerInfo: Customer;
  handleUpdateCustomerInfo: (customerInfo: Partial<Customer>) => void;
};

const CustomerInfoModal = ({ isOpen, onClose, customerInfo, handleUpdateCustomerInfo }: Props) => {
  const initialFields = useMemo(() => {
    return {
      first_name: customerInfo?.first_name,
      last_name: customerInfo?.last_name,
      email: customerInfo?.email,
    };
  }, [customerInfo]);
  const [fields, setFields] = useState(initialFields);
  const [errors, setErrors] = useState<FormErrorType<typeof initialFields>>({});

  const handleUpdateFields = useCallback((value: string, key: keyof typeof initialFields) => {
    setErrors((errors) => {
      return { ...errors, [key]: '' };
    });
    setFields((fields) => {
      return { ...fields, [key]: value };
    });
  }, []);

  const handleValidation = useCallback(
    (key: keyof typeof initialFields) => {
      const err = formValidation(key, fields[key]);
      setErrors((errors) => {
        return { ...errors, [key]: err };
      });
    },
    [fields]
  );

  const [submitLoad, setSubmitLoad] = useState(false);

  const handleSave = useCallback(() => {
    const hasErrors = checkFormDisablity(fields);
    if (hasErrors) return;

    setSubmitLoad(true);
    api
      .updateCustomerInfo({ ...fields })
      .then((resp) => {
        handleUpdateCustomerInfo(fields);
        setSubmitLoad(false);
        onClose();
      })
      .catch((err) => setSubmitLoad(false));
  }, [fields, onClose, handleUpdateCustomerInfo]);

  const closeModal = useCallback(() => {
    setFields(initialFields);
    onClose();
  }, [initialFields, onClose]);

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      title={t('edit_contract_title')}
      primaryAction={{ content: t('save'), onAction: handleSave, loading: submitLoad }}
      secondaryActions={[
        {
          content: t('button_cancel'),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              label={t('first_name')}
              autoComplete="off"
              id="first_name"
              value={fields?.first_name}
              onChange={handleUpdateFields}
              error={errors?.first_name}
              onBlur={() => handleValidation('first_name')}
            />
            <TextField
              label={t('last_name')}
              autoComplete="off"
              id="last_name"
              value={fields?.last_name}
              onChange={handleUpdateFields}
              error={errors?.last_name}
              onBlur={() => handleValidation('last_name')}
            />
          </FormLayout.Group>
          <TextField
            label={t('email')}
            autoComplete="off"
            id="email"
            type="email"
            value={fields?.email}
            onChange={handleUpdateFields}
            error={errors?.email}
            onBlur={() => handleValidation('email')}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
};

export default CustomerInfoModal;
