import { useState, useEffect } from 'react';
import { BlockStack, Box, Checkbox, Divider, FormLayout, Select, TextField } from '@shopify/polaris';
import { useDeliveryIntervalOptions } from 'hooks/useDeliveryIntervalOptions';
import createIterableSelectOptions from 'utils/createIterableSelectOptions';
import { t } from 'react-simple-multi-language';
import { ContractSellingPlan } from 'types/SubscriptionContract';

type Props = {
  contractSellingPlan: ContractSellingPlan;
  updateField: (value: string, key: keyof ContractSellingPlan) => void;
};

export const ContractFrequency = ({ contractSellingPlan, updateField }: Props) => {
  const [activePrepaid, setActivePrepaid] = useState(Number(contractSellingPlan.prePaidBillingCount) > 1);

  const deliveryIntervalOptions = useDeliveryIntervalOptions();

  const prepayBillingOptions = createIterableSelectOptions({
    startsFrom: 1,
    endsUntil: 20,
    IndexedLabel: { 1: t('single_delivery') },
    generateLabel(iterate) {
      return t('plural_delivery', { delivery_interval: String(iterate) });
    },
  });

  useEffect(() => {
    if (!activePrepaid) updateField('1', 'prePaidBillingCount');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePrepaid]);

  return (
    <>
      <Box paddingBlockEnd="500">
        <BlockStack gap="150">
          <p>{t('Order_frequency')}</p>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                autoComplete="off"
                label={undefined}
                id="intervals"
                onChange={updateField}
                value={contractSellingPlan.intervals}
                type="number"
              />

              <Select
                label={undefined}
                options={deliveryIntervalOptions}
                id="deliveryIntervals"
                onChange={updateField}
                value={contractSellingPlan.deliveryIntervals}
              />
              <Checkbox checked={activePrepaid} label={t('prepaid')} onChange={setActivePrepaid} />
            </FormLayout.Group>

            {activePrepaid && (
              <FormLayout.Group>
                <Select
                  label={t('bill_every')}
                  options={prepayBillingOptions}
                  id="prePaidBillingCount"
                  onChange={updateField}
                  value={contractSellingPlan.prePaidBillingCount}
                />
              </FormLayout.Group>
            )}
          </FormLayout>
        </BlockStack>
      </Box>
      <Divider />
    </>
  );
};
