import { useEffect, useMemo, useState } from 'react';

const getRemainingTime = (date: Date) => {
  return date.getTime() - Date.now();
};

export const useLiveRemainingTime = (offsetInSeconds: number) => {
  const expireDate = useMemo(() => {
    const currentDate = new Date();
    return new Date(currentDate.setSeconds(currentDate.getSeconds() + offsetInSeconds));
  }, [offsetInSeconds]);

  const [remainingTime, setRemainingTime] = useState(getRemainingTime(expireDate));

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(getRemainingTime(expireDate));
      if (getRemainingTime(expireDate) < 0) clearInterval(interval);
    }, 1000);

    return () => clearInterval(interval);
  }, [expireDate]);

  return remainingTime;
};
