import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Badge, Button, InlineStack, Layout, MenuActionDescriptor, Page } from '@shopify/polaris';

import { api } from '../api/index';
import { PATHS } from 'config/constants';
import SubscriptionDetails from '@/single-subscription/SubscriptionDetails';
import CustomerDetails from '@/single-subscription/CustomerDetails';
import PaymentMethodDetails from '@/single-subscription/PaymentMethodDetails';
import OrderHistoryTable from '@/single-subscription/OrderHistoryTable';

import { useSubscriptionStatus } from 'hooks/useSubscriptionStatus';
import PageWithSidebarInitialLoading from '@/shared/loaders/PageWithSidebarInitialLoading';
import NextOrderCard from '@/single-subscription/NextOrderCard';
import CancelSubscriptionModal from '@/modals/CancelSubscriptionModal';
import { getSubscriptionContractStatusUpdateHandler } from 'utils/getSubscriptionContractStatusUpdateHandler';
import { showToast } from 'states/actions/toast';
import { useDispatch } from 'react-redux';
import FailedPaymentBanner from '@/banners/FailedPaymentBanner';
import DiscountCode from '@/single-subscription/DiscountCode';
import { t } from 'react-simple-multi-language';
import { Status, SubscriptionContract } from 'types/SubscriptionContract';
import { DiscountInfo } from 'types/DiscountInfo';
import { CustomerPermissions } from 'types/CustomerPermissions';
import { Address, Customer } from 'types/Customer';
import { Status as PolarisStatus } from 'types/Polaris';
import { CancellationReasonsData } from 'types/CancelationManagement';
import { ContractNote } from '@/single-subscription/ContractNote';
import { useToggle } from 'hooks/useToggle';
import { EditSubscriptionModal } from '@/single-subscription/editSubscriptionModal/EditSubscriptionModal';

export const PermissionsContext = React.createContext<{ permissions: CustomerPermissions }>(
  {} as { permissions: CustomerPermissions }
);

const SingleSubscription = () => {
  const dispatch = useDispatch();

  const params = useParams<{ id: string }>();
  const subscriptionId = parseInt(params?.id);

  const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionContract>({} as SubscriptionContract);
  const [discountInfo, setDiscountInfo] = useState<DiscountInfo | null>(null);

  const [subscriptionLoading, setSubscriptionLoading] = useState(true);
  const [statusLoading, setStatusLoading] = useState({
    CANCELLED: false,
    PAUSED: false,
    ACTIVE: false,
  });

  const [openCancelSubscriptionModal, setOpenCancelSubscriptionModal] = useState(false);
  const [openEditSubscriptionModal, toggleEditSubscriptionModal] = useToggle(false);

  const [permissions, setPermissions] = useState<CustomerPermissions>({} as CustomerPermissions);

  useEffect(() => {
    api.getCancelationMAnagementData();
    Promise.all([api.getSubscriptionContract(subscriptionId), api.getCustomerPermissions()])
      .then(([subscriptionInfo, permissions]) => {
        setPermissions(permissions.data);
        setSubscriptionInfo(subscriptionInfo.data);
      })
      .finally(() => {
        setSubscriptionLoading(false);
      });
  }, [subscriptionId]);

  const handleChangeSubscriptionStatus = useCallback(
    (status: Status, data?: CancellationReasonsData) => {
      setStatusLoading((statusLoading) => {
        return { ...statusLoading, [status]: true };
      });
      const updateStatusHandler = getSubscriptionContractStatusUpdateHandler(status);

      if (updateStatusHandler) {
        updateStatusHandler(subscriptionInfo?.id, data)
          .then((resp) => {
            setSubscriptionInfo((subscriptionInfo) => {
              return { ...subscriptionInfo, status };
            });
            setStatusLoading({ CANCELLED: false, PAUSED: false, ACTIVE: false });
            setOpenCancelSubscriptionModal(false);
          })
          .catch((err) => {
            showToast(dispatch)(err.message, { error: true });

            setStatusLoading({ CANCELLED: false, PAUSED: false, ACTIVE: false });
          });
      }
    },
    [subscriptionInfo, dispatch]
  );

  const handleUpdateDeliveryAddress = useCallback((address: Partial<Address>) => {
    setSubscriptionInfo((subscriptionInfo) => {
      return {
        ...subscriptionInfo,
        delivery_method: {
          __typename: subscriptionInfo.delivery_method.__typename,
          address: { ...subscriptionInfo.delivery_method.address, ...address } as Address,
        },
      };
    });
  }, []);

  const handleUpdateCustomerInfo = useCallback((customerInfo: Partial<Customer>) => {
    setSubscriptionInfo((subscriptionInfo) => {
      return { ...subscriptionInfo, customer: { ...subscriptionInfo?.customer, ...customerInfo } };
    });
  }, []);

  const handleRemoveItem = useCallback((idsToRemove: string[]) => {
    setSubscriptionInfo((subscriptionInfo) => {
      return {
        ...subscriptionInfo,
        lines: subscriptionInfo?.lines?.filter((line) => !idsToRemove?.includes(line?.id)),
      };
    });
  }, []);

  const toggleCancelSubscriptionModal = useCallback(() => {
    setOpenCancelSubscriptionModal((openCancelSubscriptionModal) => !openCancelSubscriptionModal);
  }, []);

  const updateNextBillingDate = useCallback((date: Date) => {
    setSubscriptionInfo((subscriptionInfo) => {
      return { ...subscriptionInfo, next_billing_date: date };
    });
  }, []);

  const applyDiscountInfo = (data: DiscountInfo) => {
    setDiscountInfo(data);
  };

  const onSubscriptionCancel = (data?: CancellationReasonsData) =>
    handleChangeSubscriptionStatus(Status.CANCELLED, data);

  const subscriptionStatus = useSubscriptionStatus(subscriptionInfo?.status);
  const lastPaymentHasFailed = subscriptionInfo.last_payment_status === 'FAILED';

  if (subscriptionLoading) return <PageWithSidebarInitialLoading />;

  return (
    <div className="single-subscription-page-wrapper">
      <Page
        backAction={{ content: 'Subscriptions', url: PATHS.SUBSCRIPTIONS }}
        title={t('subscription_title', { contract_id: String(subscriptionId) })}
        titleMetadata={<Badge tone={subscriptionStatus?.style as PolarisStatus}>{subscriptionStatus?.title}</Badge>}
        primaryAction={
          subscriptionInfo?.status === Status.PAUSED
            ? {
                content: t('button_resume'),
                onAction: () => handleChangeSubscriptionStatus(Status.ACTIVE),
                loading: statusLoading.ACTIVE,
              }
            : null
        }
        secondaryActions={[
          (subscriptionInfo?.status === Status.ACTIVE && permissions.pause_subscription
            ? {
                content: t('button_pause'),
                onAction: () => handleChangeSubscriptionStatus(Status.PAUSED),
                destructive: true,
                outline: true,
                loading: statusLoading.PAUSED,
              }
            : null) as MenuActionDescriptor,
        ].filter((value) => value)}
      >
        <PermissionsContext.Provider value={{ permissions }}>
          <Layout>
            <Layout.Section variant="fullWidth">
              <NextOrderCard subscription={subscriptionInfo} updateNextBillingDate={updateNextBillingDate} />
            </Layout.Section>

            <Layout.Section>
              {lastPaymentHasFailed && <FailedPaymentBanner />}
              <SubscriptionDetails
                discountInfo={discountInfo}
                subscription={subscriptionInfo}
                updateSubscription={setSubscriptionInfo}
                handleRemoveItem={handleRemoveItem}
              />
              <OrderHistoryTable orders={subscriptionInfo?.orders ?? []} />
              {/* <SubscriptionTimeline /> */}
            </Layout.Section>
            <Layout.Section variant="oneThird">
              {!!permissions.apply_discount_code && (
                <DiscountCode subscriptionId={subscriptionId} onDiscountApplied={applyDiscountInfo} />
              )}
              <PaymentMethodDetails paymentMethod={subscriptionInfo?.customer_payment_method} />
              <CustomerDetails
                customerInfo={subscriptionInfo?.customer}
                deliveryAddress={subscriptionInfo?.delivery_method?.address}
                subscriptionId={subscriptionInfo?.id}
                handleUpdateDeliveryAddress={handleUpdateDeliveryAddress}
                handleUpdateCustomerInfo={handleUpdateCustomerInfo}
              />
              <ContractNote
                initialNote={subscriptionInfo.note}
                subscriptionId={subscriptionId}
                setSubscriptionInfo={setSubscriptionInfo}
              />
            </Layout.Section>
            <Layout.AnnotatedSection>
              <InlineStack align="end">
                {(subscriptionInfo?.status === Status.ACTIVE || subscriptionInfo?.status === Status.PAUSED) &&
                  permissions.cancel_subscription && (
                    <Button
                      variant="secondary"
                      onClick={toggleCancelSubscriptionModal}
                      loading={statusLoading.CANCELLED}
                    >
                      {t('button_cancel_subscription')}
                    </Button>
                  )}
              </InlineStack>
            </Layout.AnnotatedSection>

            <CancelSubscriptionModal
              isOpen={openCancelSubscriptionModal}
              onClose={toggleCancelSubscriptionModal}
              onSubscriptionCancel={onSubscriptionCancel}
            />
            {openEditSubscriptionModal && (
              <EditSubscriptionModal contractLines={subscriptionInfo.lines} onClose={toggleEditSubscriptionModal} />
            )}
          </Layout>
        </PermissionsContext.Provider>
      </Page>
    </div>
  );
};

export default SingleSubscription;
