import { SellingPlanPricingAdjustmentType } from 'types/SellingPlan';

export const discountFormatter = (
  discountType: SellingPlanPricingAdjustmentType,
  discountValue: string | number = 0,
  currencySymbol = '$'
) => {
  const isPercentDiscount = discountType === 'PERCENTAGE';
  return isPercentDiscount ? `${discountValue}%` : `${currencySymbol}${discountValue}`;
};
