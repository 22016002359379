import { constraints } from 'config/formFieldConstraints';

type Fields = { [key: string]: any };

export const checkFormDisablity = (fields: Fields) => {
  return Object.entries(fields).reduce((prev, [key, value]) => {
    const validation: any = constraints[key as keyof typeof constraints];
    return (
      Boolean(
        (!validation?.presence?.allowEmpty && !value) ||
          value <= validation?.numericality?.greaterThan ||
          (validation?.length?.maximum && value?.length > validation?.length?.maximum) ||
          (validation?.format?.pattern && !value.match(validation?.format?.pattern))
      ) || prev
    );
  }, false);
};
