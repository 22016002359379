import Loader from '@/shared/loaders/Loader';
import { t } from 'react-simple-multi-language';
import { ModalProps } from 'types/general';
import { CancellationReasonsData } from 'types/CancelationManagement';
import { LoadingTypes, useCancelContract } from 'hooks/useCancelContract';
import { Modal } from '@shopify/polaris';

type Props = ModalProps & {
  onSubscriptionCancel: (data?: CancellationReasonsData) => void;
};

const CancelSubscriptionModal = ({ isOpen, onSubscriptionCancel, onClose }: Props) => {
  const { cancelationManagementData, cancellationReasonsForm, currentStepConfig, loading, setCancellationReasonsForm } =
    useCancelContract(onSubscriptionCancel);

  const isContentLoading = loading === LoadingTypes.CONTENT_LOADING;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={currentStepConfig.title}
      secondaryActions={[
        {
          content: t('button_cancel_subscription'),
          onAction: currentStepConfig.onAction,
          destructive: true,
          outline: true,
          loading: loading === LoadingTypes.BUTTON_LOADING,
          disabled: isContentLoading || currentStepConfig.actionButtonDisabled,
        },
        { content: t('keep_subscription'), onAction: onClose, disabled: isContentLoading },
      ]}
    >
      <Modal.Section>
        {isContentLoading ? (
          <Loader />
        ) : (
          <currentStepConfig.component
            cancelationReasonForm={cancellationReasonsForm}
            cancellationData={cancelationManagementData}
            setCancellationReasonForm={setCancellationReasonsForm}
          />
        )}
      </Modal.Section>
    </Modal>
  );
};

export default CancelSubscriptionModal;
