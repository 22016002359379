import { publicApi } from 'api/index';
import { useState } from 'react';
import { Store } from 'types/Authentication';
import { handleErrorPayload } from 'utils/handleErrorPayload';

export const useLoginForm = () => {
  const [stepId, setStepId] = useState(0);

  const [email, setEmail] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [stores, setStores] = useState<Store[]>([]);

  const [sendEmailLoading, setSendEmailLoading] = useState(false);

  const goToNextStep = () => setStepId((id) => id + 1);

  const onChangeEmail = (value: string) => setEmail(value.toLowerCase());
  const onUpdateStores = (stores: Store[]) => setStores(stores);

  const onRequestCode = () => {
    setSendEmailLoading(true);

    publicApi
      .requestAuthCode(email)
      .then((resp) => {
        if (stepId === 0) goToNextStep();
      })
      .catch((err) => {
        const errorMessage = err?.response?.status === 404 ? 'Email address not found' : handleErrorPayload(err);
        setEmailErrorMessage(errorMessage);
        setTimeout(() => setEmailErrorMessage(''), 4000);
      })
      .finally(() => setSendEmailLoading(false));
  };

  return {
    stepId,
    goToNextStep,
    email,
    onChangeEmail,
    sendEmailLoading,
    emailErrorMessage,
    stores,
    onUpdateStores,
    onRequestCode,
  };
};
