import React, { Fragment, useCallback, useState } from 'react';

import DeliveryAddressModal from '@/modals/DeliveryAddressModal';
import CustomerInfoModal from '@/modals/CustomerInfoModal';
import { t } from 'react-simple-multi-language';
import { Address, Customer } from 'types/Customer';
import { FeaturedCard } from '@/shared/FeaturedCard/FeaturedCard';
import { FeaturedCardSection } from '@/shared/FeaturedCard/FeaturedCardSection';

type Props = {
  customerInfo: Customer;
  deliveryAddress: Address | undefined;
  subscriptionId: number;
  handleUpdateDeliveryAddress: (address: Partial<Address>) => void;
  handleUpdateCustomerInfo: (customerInfo: Partial<Customer>) => void;
};

const CustomerDetails = ({ customerInfo, deliveryAddress, ...restProps }: Props) => {
  const [openDeliveryAddressModal, setOpenDeliveryAddressModal] = useState(false);
  const [openCustomerInfoModal, setOpenopenCustomerInfoModal] = useState(false);

  const toggleDeliveryAddressModal = useCallback(() => {
    setOpenDeliveryAddressModal((openDeliveryAddressModal) => !openDeliveryAddressModal);
  }, []);

  const toggleCustomerInfoModal = useCallback(() => {
    setOpenopenCustomerInfoModal((openCustomerInfoModal) => !openCustomerInfoModal);
  }, []);

  return (
    <div className="customer-details-card">
      <FeaturedCard title={t('customer')} actions={[{ content: t('edit'), onAction: toggleCustomerInfoModal }]}>
        <FeaturedCardSection>
          <p>{`${customerInfo?.first_name} ${customerInfo?.last_name}`}</p>
          <p>{customerInfo?.email}</p>
          {/* <p>1 subscription</p> */}
        </FeaturedCardSection>
        <FeaturedCardSection
          title={t('shipping_address')}
          actions={[{ content: t('edit'), onAction: toggleDeliveryAddressModal }]}
        >
          <>
            {deliveryAddress?.first_name && deliveryAddress?.last_name && (
              <p>{`${deliveryAddress?.first_name} ${deliveryAddress?.last_name}`}</p>
            )}
            {deliveryAddress?.address1 && deliveryAddress.city && deliveryAddress.country ? (
              <>
                <p>
                  {deliveryAddress?.address1}
                  {deliveryAddress?.address2 ? `, ${deliveryAddress?.address2}` : ''}
                </p>
                <p>{`${deliveryAddress?.city}, ${deliveryAddress?.zip}`}</p>
                <p>{deliveryAddress?.country}</p>
                <p>{deliveryAddress?.phone}</p>
              </>
            ) : (
              <p>{t('no_shipping_address')}</p>
            )}
          </>
        </FeaturedCardSection>
        <FeaturedCardSection title={t('billing_address')}>
          <p>{t('same_address')}</p>
        </FeaturedCardSection>

        <DeliveryAddressModal
          isOpen={openDeliveryAddressModal}
          onClose={toggleDeliveryAddressModal}
          deliveryAddress={deliveryAddress}
          {...restProps}
        />
        <CustomerInfoModal
          isOpen={openCustomerInfoModal}
          onClose={toggleCustomerInfoModal}
          customerInfo={customerInfo}
          {...restProps}
        />
      </FeaturedCard>
    </div>
  );
};

export default CustomerDetails;
