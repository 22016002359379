import { Counter } from '@/shared/Counter';
import { Button, InlineStack, Text } from '@shopify/polaris';
import { useLiveRemainingTime } from 'hooks/useLiveRemainingTime';
import { useState } from 'react';

type Props = {
  onResendCode: () => void;
};

export const ResendCode = ({ onResendCode }: Props) => {
  const [counterOffset, setCounterOffset] = useState(90);

  const remainingTime = useLiveRemainingTime(counterOffset);

  const resendCode = () => {
    onResendCode();
    setCounterOffset((offset) => offset - 1);
  };

  return (
    <InlineStack align="center" blockAlign="center" gap="200">
      <Text as="span" variant="bodyMd" tone="subdued">
        {remainingTime < 0 ? "Didn't get a code?" : 'Resend code in'}
      </Text>
      {remainingTime < 0 ? (
        <Button onClick={resendCode} variant="plain">
          Click to resend
        </Button>
      ) : (
        <Counter remainingTime={remainingTime} />
      )}
    </InlineStack>
  );
};
