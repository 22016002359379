import { AxiosError } from 'axios';

type ReturnError = AxiosError & { message: string };

const errorCodes = [400, 403];

export const errorHandler = (err: AxiosError) => {
  let errorObject: ReturnError & { message: string } = {} as ReturnError;

  if (err?.response || err?.request) errorObject = { ...err };

  if (err?.response?.status && errorCodes.includes(err.response.status)) {
    if (Array.isArray(err?.response?.data)) {
      errorObject.message = err?.response?.data[0];
    } else if (typeof err?.response?.data === 'object' && err?.response?.data?.message) {
      errorObject.message = err?.response?.data?.message;
    } else {
      errorObject.message = 'something went try.try again later';
    }
  } else {
    errorObject.message = 'check your connection and try again!';
  }

  return errorObject;
};
