import { Button, ButtonGroup, InlineStack, Text } from '@shopify/polaris';
import { useContext } from 'react';

import { convertToLocaleDatetime } from 'utils/dateTimeUtils';
import { getPriceWithCurrencyCode } from 'utils/currency';
import { useTotalPrice } from 'hooks/useTotalPrice';
import { useCurrencyCode } from 'hooks/useCurrencyCode';
import SkipUpcomingOrderModal from '@/modals/SkipUpcomingOrderModal';
import { useToggle } from 'hooks/useToggle';
import { SetNextBillingOrderModal } from '@/modals/SetNextBillingOrderModal';
import { PermissionsContext } from '../../pages/SingleSubscription';
import { t } from 'react-simple-multi-language';
import { Status, SubscriptionContract } from 'types/SubscriptionContract';
import { FeaturedCard } from '@/shared/FeaturedCard/FeaturedCard';

type Props = {
  updateNextBillingDate: (date: Date) => void;
  subscription: SubscriptionContract;
};

const NextOrderCard = ({ subscription, updateNextBillingDate }: Props) => {
  const { permissions } = useContext(PermissionsContext);

  const totalPrice = useTotalPrice(subscription);
  const currencyCode = useCurrencyCode(subscription);

  const [openSkipUpcomingOrderModal, toggleSkipUpcomingOrderModal] = useToggle(false);
  const [isNextBillingDateModalOpen, toggleNextBillingDateModal] = useToggle(false);

  const isContractActive = subscription.status === Status.ACTIVE;

  return (
    <div className="next-order-card">
      <FeaturedCard sectioned>
        <InlineStack blockAlign="center" align="space-between" gap="200">
          <InlineStack blockAlign="center" gap="200">
            <Text as="h6" variant="headingMd">
              {t('next_order')}
            </Text>
            <p>
              {convertToLocaleDatetime(subscription?.next_billing_date)},{' '}
              <Text as="span" fontWeight="semibold" variant="bodyMd">
                {getPriceWithCurrencyCode(totalPrice?.toFixed(2), currencyCode)}
              </Text>
            </p>
          </InlineStack>
          {isContractActive && (
            <ButtonGroup>
              {!!permissions.reschedule && (
                <Button onClick={toggleNextBillingDateModal} variant="plain">
                  {t('reschedule_button')}
                </Button>
              )}
              {!!permissions.skip_subscription && (
                <Button onClick={toggleSkipUpcomingOrderModal} variant="plain">
                  {t('button_skip')}
                </Button>
              )}
            </ButtonGroup>
          )}
        </InlineStack>

        <SkipUpcomingOrderModal
          isOpen={openSkipUpcomingOrderModal}
          subscriptionId={subscription?.id}
          onClose={toggleSkipUpcomingOrderModal}
          updateNextBillingDate={updateNextBillingDate}
        />
        <SetNextBillingOrderModal
          nextBillingDate={subscription?.next_billing_date}
          isOpen={isNextBillingDateModalOpen}
          onClose={toggleNextBillingDateModal}
          contractId={subscription?.id}
          updateNextBillingDate={updateNextBillingDate}
        />
      </FeaturedCard>
    </div>
  );
};

export default NextOrderCard;
