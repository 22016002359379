export const DEFAULT_BASE_URL = 'https://api-beta-subify.hengam.io';
export const TABLE_ROWS_PER_PAGE = 6;

export const SUBIFY_APP_LISTING_URL = 'https://subify.info/?utm_source=Portal';

export const PATHS = {
  LOGIN: '/login/',
  SUBSCRIPTIONS: '/',
};

export const EXTERNAL_URLS = {
  LEARN_MORE: {
    PORTAL_LOGIN: 'https://help.hengam.io/en/articles/8009930-how-can-i-log-in-to-my-portal',
  },
};
