import { BlockStack, InlineStack, Text } from '@shopify/polaris';

import { ResourceInfo } from '@/shared/links/ResourceInfo';
import { getPriceWithCurrencyCode } from 'utils/currency';
import { useCycleDiscounts } from 'hooks/useCycleDiscounts';
import InlineInfoMessage from '@/shared/InlineInfoMessage';
import { getDiscountPriceFromDiscountInformation } from 'utils/getDiscountPriceFromDiscountInformation';
import { t } from 'react-simple-multi-language';
import { SubscriptionContractLine } from 'types/SubscriptionContract';
import { DiscountInfo } from 'types/DiscountInfo';
import { FeaturedCardSection } from '@/shared/FeaturedCard/FeaturedCardSection';
import { isLineHasFlatRatePrice } from 'utils/isLineHasFlatRatePrice';

type Props = {
  item: SubscriptionContractLine;
  discountInfo: DiscountInfo | null;
};

const SubscriptionLine = ({ item, discountInfo }: Props) => {
  const { amount, currency_code } = item?.current_price;
  const cycleDiscount = useCycleDiscounts(
    currency_code,
    item?.pricing_policy?.cycle_discounts,
    isLineHasFlatRatePrice(item)
  );

  if (!cycleDiscount) return null;

  const { firstDiscount, recurringDiscount, recurringCycle } = cycleDiscount;

  const discountedPrice = getDiscountPriceFromDiscountInformation(item.id, discountInfo);
  const discountPercentage = item?.pricing_policy?.cycle_discounts[0]?.adjustment_value?.percentage;
  return (
    <div className="subscription-line-wrapper">
      <FeaturedCardSection>
        <BlockStack gap="200">
          <div className="subscription-line-wrapper">
            <InlineStack align="space-between" wrap={true}>
              <ResourceInfo
                resource={{
                  title: item?.title,
                  subtitle: item?.variant_title,
                  image: item?.variant_image_src,
                }}
              >
                <p>
                  {getPriceWithCurrencyCode(amount, currency_code)}{' '}
                  {!!firstDiscount && (
                    <Text as="span" fontWeight="semibold" variant="bodyMd">
                      {t('first_discount', { first_discount: String(firstDiscount) })}
                    </Text>
                  )}
                </p>
              </ResourceInfo>
              <div className="subscription-line-price-wrapper">
                <BlockStack gap="200">
                  <Text as="span" fontWeight="semibold" variant="bodyMd">
                    x{item?.quantity}
                  </Text>
                  <div>
                    <p>
                      {getPriceWithCurrencyCode(discountedPrice || amount, currency_code)}{' '}
                      {discountPercentage && (
                        <Text as="span" fontWeight="semibold" variant="bodyMd">
                          {t('discount_percentage', { discount_percentage: String(discountPercentage) })}
                        </Text>
                      )}
                    </p>
                    {discountedPrice && (
                      <p>
                        <Text as="span" tone="subdued" variant="bodyMd">
                          <span className="discounted-price">{getPriceWithCurrencyCode(amount, currency_code)}</span>
                        </Text>
                      </p>
                    )}
                  </div>
                </BlockStack>
              </div>
            </InlineStack>
          </div>

          {!!recurringCycle && (
            <InlineInfoMessage
              message={t('recurring_payment_message', {
                recurring_cycle: String(recurringCycle),
                first_discount: String(firstDiscount),
                recurring_discount: String(recurringDiscount),
              })}
            />
          )}
        </BlockStack>
      </FeaturedCardSection>
    </div>
  );
};

export default SubscriptionLine;
