import { ResourceInfo } from '@/shared/links/ResourceInfo';
import { Box, InlineStack } from '@shopify/polaris';

import { SubscriptionContractLine } from 'types/SubscriptionContract';

type Props = { line: SubscriptionContractLine; index: number };

export const SubscriptionItemRow = ({ line, index }: Props) => {
  return (
    <Box paddingBlock="400">
      <InlineStack blockAlign="center" align="space-between">
        <ResourceInfo
          resource={{ countNumber: index, image: line?.variant_image_src, title: line?.title }}
        ></ResourceInfo>
      </InlineStack>
    </Box>
  );
};
