import { Link as ReactRouterLink, NavLink as ReactRouterNavLink } from 'react-router-dom';

type RouterLinkProps = {
  children: React.ReactNode;
  download?: string;
  url: string;
  renderNavLink?: boolean;
};

const RouterLink = ({ children, renderNavLink, url = '', ...rest }: RouterLinkProps) => {
  if (isOutboundLink(url) || rest.download) {
    return (
      <a href={url} {...rest} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  const Link = (renderNavLink ? ReactRouterNavLink : ReactRouterLink) as typeof ReactRouterLink;

  return (
    <Link to={url} {...rest}>
      {children}
    </Link>
  );
};

function isOutboundLink(url: string) {
  return /^(?:[a-z][a-z\d+.-]*:|\/\/)/.test(url);
}

export default RouterLink;
