import { discountFormatter } from 'utils/discountFormatter';

import { CycleDiscount } from 'types/SubscriptionContract';
import { SellingPlanPricingAdjustmentType, SellingPlanPricingAdjustmentValue } from 'types/SellingPlan';

type CycleDiscountsHookReturnType = {
  firstDiscount: string | null;
  recurringDiscount: string | null;
  recurringCycle: number | null;
};

const isPercentageDiscount = (discountType: SellingPlanPricingAdjustmentType) => {
  return discountType === SellingPlanPricingAdjustmentType.PERCENTAGE;
};

const getDiscountValue = (
  adjustmentType: SellingPlanPricingAdjustmentType,
  adjustmentValue: SellingPlanPricingAdjustmentValue
) => {
  return isPercentageDiscount(adjustmentType) ? adjustmentValue.percentage : adjustmentValue.amount;
};

export const useCycleDiscounts = (
  currencySymbol: string,
  cycleDiscounts: CycleDiscount[] | undefined,
  isFlatRatePrice: boolean
) => {
  if (!cycleDiscounts) return null;

  return cycleDiscounts.reduce(
    (prev: CycleDiscountsHookReturnType, cycle) => {
      if (cycle.after_cycle === 0 && !isFlatRatePrice) {
        const cycleDiscountValue = getDiscountValue(cycle.adjustment_type, cycle.adjustment_value);
        if (cycleDiscountValue !== 0)
          prev['firstDiscount'] = discountFormatter(cycle.adjustment_type, cycleDiscountValue, currencySymbol);
      } else if (cycle.after_cycle > 0) {
        const recurringDiscountValue = getDiscountValue(cycle.adjustment_type, cycle.adjustment_value);
        prev['recurringDiscount'] = discountFormatter(cycle.adjustment_type, recurringDiscountValue, currencySymbol);
        prev['recurringCycle'] = cycle.after_cycle;
      }
      return prev;
    },
    { firstDiscount: null, recurringDiscount: null, recurringCycle: null }
  );
};
