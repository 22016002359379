export const convertToLocaleDatetime = (datetime: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  try {
    const dt = new Date(datetime);
    return dt.toLocaleDateString(undefined, options);
  } catch (e) {
    console.error('Error converting time to local timezone time', e);
    return '--';
  }
};

export const parseTimeDelta = (time: number) => {
  const days = Math.floor(time / (1000 * 60 * 60 * 24));
  const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((time / 1000 / 60) % 60);
  const seconds = Math.floor((time / 1000) % 60);

  return { days, hours, minutes, seconds };
};
