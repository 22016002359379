import { BlockStack, Checkbox, TextField } from '@shopify/polaris';
import { t } from 'react-simple-multi-language';
import { CancellationStepProps } from 'types/CancelationManagement';

export const SelectReasons = ({
  cancellationData,
  cancelationReasonForm,
  setCancellationReasonForm,
}: CancellationStepProps) => {
  const toggleReason = (checked: boolean, id: string) => {
    if (checked) {
      setCancellationReasonForm({
        ...cancelationReasonForm,
        reasons: [...cancelationReasonForm.reasons, { id: Number(id) }],
      });
    } else {
      setCancellationReasonForm({
        ...cancelationReasonForm,
        reasons: cancelationReasonForm.reasons.filter((reason) => Number(id) !== reason.id),
      });
    }
  };

  const onDescriptionChange = (val: string) => {
    setCancellationReasonForm({
      ...cancelationReasonForm,
      customer_reason: val,
    });
  };

  return (
    <BlockStack gap="200">
      <BlockStack gap="300">
        {cancellationData?.cancellation_reasons.map(({ id, title }) => {
          const isActive = Boolean(cancelationReasonForm.reasons.find((reason) => reason.id === id));
          return <Checkbox label={title} key={id} id={String(id)} checked={isActive} onChange={toggleReason} />;
        })}
      </BlockStack>
      <TextField
        value={cancelationReasonForm.customer_reason}
        autoComplete=""
        multiline={3}
        placeholder={t('cancel_reason_description_input_placeholder')}
        label
        labelHidden
        onChange={onDescriptionChange}
      />
    </BlockStack>
  );
};
