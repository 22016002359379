import { BlockStack, Button, Form, TextField } from '@shopify/polaris';
import { useEffect, useState } from 'react';
import { LoginStepsProps } from 'types/Authentication';

const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const LoginForm = ({
  email,
  onChangeEmail,
  sendEmailLoading,
  emailErrorMessage,
  onRequestCode,
}: LoginStepsProps) => {
  const [error, setError] = useState('');

  useEffect(() => setError(''), [email]);

  const onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!emailPattern.test(email)) return setError('Please enter a valid email address');

    onRequestCode();
  };

  return (
    <Form onSubmit={onSubmitForm}>
      <BlockStack gap="200">
        <TextField
          label="Email"
          autoComplete="off"
          value={email}
          onChange={onChangeEmail}
          error={error || emailErrorMessage}
        />

        <Button variant="primary" fullWidth submit size="large" loading={sendEmailLoading}>
          Send
        </Button>
      </BlockStack>
    </Form>
  );
};
