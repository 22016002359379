import React from 'react';
import {
  Layout,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  SkeletonBodyText,
  BlockStack,
} from '@shopify/polaris';
import { FeaturedCard } from '../FeaturedCard/FeaturedCard';
import { FeaturedCardSection } from '../FeaturedCard/FeaturedCardSection';

const PageWithSidebarInitialLoading = () => {
  return (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <FeaturedCard sectioned>
            <SkeletonBodyText />
          </FeaturedCard>
          <FeaturedCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </FeaturedCard>
          <FeaturedCard sectioned>
            <BlockStack gap="200">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </BlockStack>
          </FeaturedCard>
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <FeaturedCard>
            <FeaturedCardSection>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </FeaturedCardSection>
            <FeaturedCardSection>
              <SkeletonBodyText lines={1} />
            </FeaturedCardSection>
          </FeaturedCard>
          <FeaturedCard sectioned>
            <FeaturedCardSection>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </FeaturedCardSection>
            <FeaturedCardSection>
              <SkeletonBodyText lines={2} />
            </FeaturedCardSection>
          </FeaturedCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
};

export default PageWithSidebarInitialLoading;
