import { FeaturedCard } from '@/shared/FeaturedCard/FeaturedCard';
import { Modal, Text, TextField } from '@shopify/polaris';
import { EditIcon } from '@shopify/polaris-icons';
import { api } from 'api/index';
import { useToggle } from 'hooks/useToggle';
import { useEffect, useState } from 'react';
import { t } from 'react-simple-multi-language';

import { SubscriptionContract } from 'src/types/SubscriptionContract';

type Props = {
  initialNote: string | null;
  subscriptionId: number;
  setSubscriptionInfo: React.Dispatch<React.SetStateAction<SubscriptionContract>>;
};

export const ContractNote = ({ initialNote, subscriptionId, setSubscriptionInfo }: Props) => {
  const [note, setNote] = useState(initialNote || '');
  const [open, toggleOpen] = useToggle(false);
  const [loading, setLoading] = useState(false);

  const onEditNote = () => {
    setLoading(true);
    api
      .updateSubscriptionContract(subscriptionId, { note })
      .then((res) => {
        toggleOpen();
        setSubscriptionInfo(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!open) setNote(initialNote || '');
  }, [open, initialNote]);

  return (
    <div className="contract-note-card">
      <FeaturedCard title={t('note')} actions={[{ icon: EditIcon, onAction: toggleOpen }] as any} sectioned>
        <Text as="p" tone="subdued" variant="bodyLg">
          {initialNote || t('no_note')}
        </Text>
      </FeaturedCard>
      <Modal
        title={t('add_note')}
        open={open}
        onClose={toggleOpen}
        primaryAction={{ content: t('save'), onAction: onEditNote, loading }}
        secondaryActions={[{ content: t('button_cancel'), onAction: toggleOpen }]}
      >
        <Modal.Section>
          <TextField autoComplete="off" label={t('single_subscription.note')} onChange={setNote} value={note} />
        </Modal.Section>
      </Modal>
    </div>
  );
};
