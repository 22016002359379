import { SubscriptionContractLine } from './SubscriptionContract';

export interface DiscountInfo {
  id: number;
  lines: SubscriptionContractLine[];
  total_price: number;
}

export enum DiscountApplyingStatus {
  INIT = 'INIT',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}
