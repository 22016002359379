import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';

import Router from './Router';
import RouterLink from '@/shared/links/RouterLink';

import '@shopify/polaris/build/esm/styles.css';
import 'assets/sass/app.scss';

const App = () => {
  return (
    <BrowserRouter>
      {/* @ts-ignore */}
      <AppProvider i18n={enTranslations} theme={'light'} linkComponent={RouterLink}>
        <Router />
      </AppProvider>
    </BrowserRouter>
  );
};

export default App;
