import { AdaptedSellingPlan } from 'types/AdaptedSellingPlan';
export interface SellingPlan {
  id: number;
  description?: string;
  billing_policy: SellingPlanBillingPolicy;
  delivery_policy: SellingPlanDeliveryPolicy;
  edited?: boolean;
  name: string;
  pricing_policies: SellingPlanPricingPolicy[];
}

export interface SellingPlanBillingPolicy {
  anchors: SellingPlanAnchor[];
  interval: SellingPlanInterval;
  interval_count: number;
  max_cycles: number | null;
  min_cycles: number | null;
  __typename: SellingPlanBillingPolicyType;
}

export interface SellingPlanDeliveryPolicy {
  anchors: SellingPlanAnchor[];
  cutoff: number | null;
  interval: SellingPlanInterval;
  interval_count: number;
  pre_anchor_behavior: PreAnchorBehavior;
  __typename: SellingPlanDeliveryPolicyType;
}

export interface SellingPlanPricingPolicy {
  adjustment_type: SellingPlanPricingAdjustmentType;
  adjustment_value: SellingPlanPricingAdjustmentValue;
  after_cycle?: number;
  __typename: SellingPlanPricingPolicyType;
}

export interface SellingPlanAnchor {
  day: number | null;
  month: number | null;
  type: SellingPlanAnchorType;
}

export enum SellingPlanAnchorType {
  WEEKDAY = 'WEEKDAY',
  MONTHDAY = 'MONTHDAY',
  YEARDAY = 'YEARDAY',
}

export enum SellingPlanInterval {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum PreAnchorBehavior {
  ASAP = 'ASAP',
  NEXT = 'NEXT',
}

export enum SellingPlanPricingAdjustmentType {
  FIXED_AMOUNT = 'FIXED_AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
  PRICE = 'PRICE',
}

export interface SellingPlanPricingAdjustmentValue {
  amount?: number;
  currency_code?: string;
  percentage?: number;
}

export enum SellingPlanBillingPolicyType {
  FIXED = 'SellingPlanFixedBillingPolicy',
  RECURRING = 'SellingPlanRecurringBillingPolicy',
}

export enum SellingPlanDeliveryPolicyType {
  FIXED = 'SellingPlanFixedDeliveryPolicy',
  RECURRING = 'SellingPlanRecurringDeliveryPolicy',
}

export enum SellingPlanPricingPolicyType {
  FIXED = 'SellingPlanFixedPricingPolicy',
  RECURRING = 'SellingPlanRecurringPricingPolicy',
}

export interface SellingPlanDescriptions {
  recurringDiscountDescription?: string;
  mainDescription?: string;
}

export type GeneralSellingPlan = SellingPlan | AdaptedSellingPlan;
