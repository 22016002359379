import { Link, Icon, Text, InlineStack } from '@shopify/polaris';
import { ChevronRightIcon } from '@shopify/polaris-icons';
import { FillStackItem } from '../FillStackItems';
import { FeaturedCard } from '../FeaturedCard/FeaturedCard';

type Props = {
  title: string;
  subtitle?: string;
  url?: string;
  onAction?: () => void;
};

const ChevronLinkedCard = ({ subtitle, title, url, onAction }: Props) => {
  return (
    <div className="chevron-linked-card">
      <Link url={url} onClick={onAction} monochrome removeUnderline>
        <FeaturedCard sectioned removeBottomSpace>
          <InlineStack blockAlign="center" wrap={false}>
            <FillStackItem>
              <p>{title}</p>
              {Boolean(subtitle) && (
                <Text as="span" tone="subdued" variant="bodyMd">
                  {subtitle}
                </Text>
              )}
            </FillStackItem>
            <Icon source={ChevronRightIcon} />
          </InlineStack>
        </FeaturedCard>
      </Link>
    </div>
  );
};

export default ChevronLinkedCard;
