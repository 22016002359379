import getSymbolFromCurrency from 'currency-symbol-map';

export const getCurrencySymbol = (code: string) => {
  if (code === 'CAD') return 'CA$';
  return getSymbolFromCurrency(code ?? 'USD');
};

export const getPriceWithCurrencyCode = (amount: string | number, code: string) => {
  return `${getCurrencySymbol(code)}${amount}`;
};
