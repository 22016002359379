import { BlockStack, Icon, InlineStack } from '@shopify/polaris';
import { StoreIcon } from '@shopify/polaris-icons';
import { useHistory } from 'react-router-dom';

import ChevronLinkedCard from '@/shared/links/ChevronLinkedCard';
import { PATHS } from 'config/constants';
import { Store } from 'types/Authentication';

type Props = {
  stores: Store[];
};

export const ChooseStore = ({ stores }: Props) => {
  const history = useHistory();

  const onChooseStore = (accessToken: string) => {
    localStorage.setItem('loggedInAsUserToken', accessToken);
    history.push(PATHS.SUBSCRIPTIONS);
  };

  return (
    <BlockStack gap="300">
      {stores.map((store) => (
        <ChevronLinkedCard
          key={store.shop_domain}
          title={
            (
              <InlineStack blockAlign="center" gap="200">
                <div className="remove-icon-inline-spacing">
                  <Icon source={StoreIcon} />
                </div>
                <span>{store.shop_domain}</span>
              </InlineStack>
            ) as unknown as string
          }
          onAction={() => onChooseStore(store.access_token)}
        />
      ))}
    </BlockStack>
  );
};
