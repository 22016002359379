import { Bleed, Box, Card, MenuActionDescriptor } from '@shopify/polaris';
import { PropsWithChildren } from 'react';

import { FeaturedCardHeader } from './FeaturedCardHeader';

type Props = {
  title?: string | React.ReactNode;
  sectioned?: boolean;
  actions?: MenuActionDescriptor[];
  removeBottomSpace?: boolean;
};

export const FeaturedCard = ({
  title,
  removeBottomSpace,
  sectioned,
  children,
  actions = [],
}: PropsWithChildren<Props>) => {
  const sectionNegativeMargin = sectioned ? '0' : '400';

  return (
    <Box paddingBlockEnd={removeBottomSpace ? '0' : '400'} shadow="0">
      <Card padding="400">
        <FeaturedCardHeader title={title} actions={actions} />
        <Bleed marginInline={sectionNegativeMargin} marginBlockEnd={sectionNegativeMargin}>
          {children}
        </Bleed>
      </Card>
    </Box>
  );
};
