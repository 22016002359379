import { Icon, InlineStack } from '@shopify/polaris';
import { AlertCircleIcon } from '@shopify/polaris-icons';

type Props = {
  message: string;
};

const InlineInfoMessage = ({ message }: Props) => {
  return (
    <InlineStack blockAlign="center" gap="300">
      <Icon source={AlertCircleIcon} tone="info" />
      <p className="info-message">{message}</p>
    </InlineStack>
  );
};

export default InlineInfoMessage;
