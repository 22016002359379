import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';
import { Frame } from '@shopify/polaris';

// import TopNav from '@/layouts/TopNav';
import { useToast } from 'hooks/useToast';
import { dismissToast } from 'states/actions/toast';
import ToastMessage from '@/shared/global/ToastMessage';
import WholePageLoading from '@/shared/loaders/WholePageLoading';

import { useLoadShopIcon } from 'hooks/useLoadShopIcon';
import Logo from '@/shared/global/Logo';
import { useLoadContents } from 'hooks/useLoadContents';

type Props = {
  component: React.FC<any>;
} & RouteProps;

const MainLayout = ({ component: Component, ...restProps }: Props) => {
  const dispatch = useDispatch();
  const toast = useToast();

  const [loadingLogo, logo] = useLoadShopIcon();
  const contentsLoading = useLoadContents();

  const handleDismissToast = useCallback(() => dismissToast(dispatch), [dispatch]);

  if (!!loadingLogo || contentsLoading) return <WholePageLoading />;

  return (
    <Frame>
      {/* <TopNav /> */}
      {!loadingLogo && <Logo logo={logo} />}

      <Route children={(routeProps) => <Component {...routeProps} />} {...restProps} />

      {toast?.show && <ToastMessage handleDismiss={handleDismissToast} {...toast} />}
    </Frame>
  );
};

export default MainLayout;
