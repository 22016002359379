import React from 'react';
import { Badge, BlockStack, Button, InlineStack, Text } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';

import { useSubscriptionStatus } from 'hooks/useSubscriptionStatus';
import { ResourceInfo } from '@/shared/links/ResourceInfo';
import { PATHS } from 'config/constants';
import { convertToLocaleDatetime } from 'utils/dateTimeUtils';
import { intervalFormatter } from 'utils/intervalFormatter';
import { getPriceWithCurrencyCode } from 'utils/currency';
import { useTotalPrice } from 'hooks/useTotalPrice';
import { useCurrencyCode } from 'hooks/useCurrencyCode';
import { t } from 'react-simple-multi-language';
import { SubscriptionContract } from 'types/SubscriptionContract';
import { FeaturedCard } from '@/shared/FeaturedCard/FeaturedCard';
import { FeaturedCardSection } from '@/shared/FeaturedCard/FeaturedCardSection';

type Props = {
  subscription: SubscriptionContract;
};

const SubscriptionCard = ({ subscription }: Props) => {
  const history = useHistory();
  const subscriptionStatus = useSubscriptionStatus(subscription?.status);
  const totalPrice = useTotalPrice(subscription);
  const currencyCode = useCurrencyCode(subscription);

  const lastPaymentHasFailed = subscription.last_payment_status === 'FAILED';

  return (
    <div className="subscription-item-wrapper">
      <FeaturedCard>
        <FeaturedCardSection>
          <InlineStack align="space-between" blockAlign="center">
            <InlineStack blockAlign="center" gap="300">
              <Text as="h6" variant="headingMd">
                #{subscription?.id}
              </Text>
              <Badge tone={subscriptionStatus?.style}>{subscriptionStatus?.title}</Badge>
            </InlineStack>
            <Button size="large" url={`${PATHS.SUBSCRIPTIONS}${subscription?.id}`}>
              {t('see_more_details')}
            </Button>
          </InlineStack>
        </FeaturedCardSection>

        <div
          className="subscription-clickable-row"
          onClick={() => history.push(`${PATHS.SUBSCRIPTIONS}${subscription?.id}`)}
        >
          <FeaturedCardSection>
            <InlineStack align="space-between">
              <ResourceInfo
                resource={{
                  image: subscription?.lines[0]?.variant_image_src,
                  thumbnailSize: 'small',
                }}
              >
                <BlockStack gap="150">
                  <p style={{ fontSize: 16 }}>
                    <Text as="span" fontWeight="semibold" variant="bodyMd">
                      {t('next_order')}
                    </Text>{' '}
                    {convertToLocaleDatetime(subscription?.next_billing_date)}
                  </p>
                  <p style={{ color: '#2c6ecb', fontSize: 16 }}>
                    {subscription?.lines?.length > 1
                      ? t('product_count', { product_count: String(subscription?.lines?.length) })
                      : subscription?.lines[0]?.title}
                  </p>
                </BlockStack>
              </ResourceInfo>
              <BlockStack gap="150">
                <p style={{ fontSize: 16 }}>
                  {intervalFormatter(
                    subscription?.delivery_policy?.interval,
                    subscription?.delivery_policy?.interval_count,
                    subscription?.billing_policy?.interval_count / subscription?.delivery_policy?.interval_count
                  )}
                </p>
                <p style={{ fontSize: 16 }}>
                  <Text as="span" variant="bodyMd">
                    {t('price')}
                  </Text>{' '}
                  <Text as="span" variant="bodyMd" fontWeight="semibold">
                    {getPriceWithCurrencyCode(totalPrice, currencyCode)}
                  </Text>
                </p>
              </BlockStack>
              {lastPaymentHasFailed && (
                <InlineStack blockAlign="center" gap="300">
                  <Text as="span" fontWeight="semibold" variant="bodyMd">
                    {t('last_payment')}
                  </Text>
                  <Badge tone="critical">{t('status_failed')}</Badge>
                </InlineStack>
              )}
            </InlineStack>
          </FeaturedCardSection>
        </div>
      </FeaturedCard>
    </div>
  );
};

export default SubscriptionCard;
