import { BlockStack, Button, Form, InlineError, InlineStack, TextField } from '@shopify/polaris';
import { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ResendCode } from './ResendCode';
import { publicApi } from 'api/index';
import { PATHS } from 'config/constants';
import { handleErrorPayload } from 'utils/handleErrorPayload';
import { LoginStepsProps } from 'types/Authentication';

export const VerifyForm = ({ email, onUpdateStores, goToNextStep, onRequestCode }: LoginStepsProps) => {
  const history = useHistory();

  const [code, setCode] = useState(new Array(6).fill(''));
  const [errorMessage, setErrorMessage] = useState('');
  const [submitLoad, setSubmitLoad] = useState(false);

  const focusInputById = (id: string) => {
    const input = document.getElementById(id);
    if (input) input.focus();
  };

  const onChangeCode = (value: string, key: string) => {
    setCode((code) => code.map((c, index) => (index === Number(key) ? value : c)));
    if (!!value) focusInputById(`${Number(key) + 1}`);
  };

  const onPasteCode = (e: ClipboardEvent) => {
    e.preventDefault();
    const copiedText = (e.clipboardData || (window as any).clipboardData).getData('text');
    const code = copiedText.replace(/\D/g, '');
    if (code.length === 6) {
      setCode(code.split(''));
    }
  };

  const onBackSpace = (e: KeyboardEvent, index: number) => {
    if (e.keyCode === 8 && index > 0) {
      e.preventDefault();

      const value = (e.target as HTMLInputElement).value;

      onChangeCode('', String(index - (value ? 0 : 1)));

      if (!value) focusInputById(String(index - 1));
    }
  };

  useEffect(() => {
    focusInputById('0');
    const verifyInputs = document.querySelectorAll('.verify-code-input input');

    verifyInputs.forEach((input, index) => {
      input.addEventListener('keydown', (e) => onBackSpace(e as KeyboardEvent, index));
      input.addEventListener('paste', onPasteCode as EventListener);
    });

    return () => {
      verifyInputs.forEach((input) => {
        input.removeEventListener('paste', onPasteCode as EventListener);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSubmitLoad(true);

    publicApi
      .verifyAuthCode(email, code.join(''))
      .then((resp) => {
        const stores = resp.data.customers;
        onUpdateStores(stores);

        if (stores.length === 1) {
          localStorage.setItem('loggedInAsUserToken', stores[0].access_token);
          history.push(PATHS.SUBSCRIPTIONS);
        } else {
          goToNextStep();
        }
      })
      .catch((err) => {
        const errorMessage = err?.response?.status === 401 ? 'code is incorrect' : handleErrorPayload(err);
        setErrorMessage(errorMessage);
        setTimeout(() => setErrorMessage(''), 4000);
      })
      .finally(() => setSubmitLoad(false));
  };

  const leftDigitsToEnter = code.filter((c) => !c).length;

  return (
    <Form onSubmit={onSubmitForm}>
      <div className="verify-code-input">
        <BlockStack gap="200">
          <BlockStack gap="300">
            <InlineStack align="center" wrap={false} gap="200">
              {code.map((c, index) => (
                <TextField
                  key={index}
                  label="Code"
                  labelHidden
                  type="number"
                  autoComplete="off"
                  min={0}
                  max={9}
                  id={`${index}`}
                  value={c}
                  onChange={onChangeCode}
                />
              ))}
            </InlineStack>

            {!!errorMessage && <InlineError fieldID="emailError" message={errorMessage} />}
          </BlockStack>

          <Button variant="primary" fullWidth submit size="large" loading={submitLoad} disabled={!!leftDigitsToEnter}>
            {code.every((c) => !!c) ? 'Verify' : `${leftDigitsToEnter} digits left`}
          </Button>

          <ResendCode onResendCode={onRequestCode} />
        </BlockStack>
      </div>
    </Form>
  );
};
