import { ReactNode } from 'react';
import { Box, MenuActionDescriptor } from '@shopify/polaris';

import { FeaturedCardHeader } from './FeaturedCardHeader';

type Props = {
  title?: string | ReactNode;
  children?: ReactNode;
  actions?: MenuActionDescriptor[];
};

export const FeaturedCardSection = ({ children, title, actions }: Props) => {
  return (
    <Box padding="400" paddingBlockStart="300">
      <FeaturedCardHeader title={title} isSectionHeader actions={actions} />
      {children}
    </Box>
  );
};
