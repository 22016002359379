import { Dispatch } from 'redux';
import { ToastActionTypes } from 'states/constants';
import { ToastAction } from 'states/reducers/toast';

export const showToast =
  (dispatch: Dispatch) =>
  (message: string, options: Omit<ToastAction['payload'], 'message'> = {}) => {
    dispatch({ type: ToastActionTypes.SHOW_TOAST, payload: { message, show: true, ...options } });
  };

export const dismissToast = (dispatch: Dispatch) => dispatch({ type: ToastActionTypes.DISMISS_TOAST });
