import React, { useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextContainer, Spinner, Text } from '@shopify/polaris';

import { api } from 'api/index';
import { showToast } from 'states/actions/toast';
import { t } from 'react-simple-multi-language';
import { CustomerPaymentMethod } from 'types/CustomerPaymentMethod';
import { PermissionsContext } from 'pages/SingleSubscription';
import { FeaturedCard } from '@/shared/FeaturedCard/FeaturedCard';

type Props = {
  paymentMethod: CustomerPaymentMethod;
};

const PaymentMethodDetails = ({ paymentMethod }: Props) => {
  const dispatch = useDispatch();

  const { permissions } = useContext(PermissionsContext);

  const [submitLoad, setSubmitLoad] = useState(false);

  const sendLinkToUpdateCard = useCallback(() => {
    setSubmitLoad(true);

    api
      .sendEmailToUpdatePaymentMethod(paymentMethod?.id)
      .then((resp) => {
        showToast(dispatch)(t('payment_update_message'));
        setSubmitLoad(false);
      })
      .catch((err) => setSubmitLoad(false));
  }, [paymentMethod, dispatch]);

  if (!paymentMethod?.id) return null;

  const expireMonth = String(paymentMethod?.instrument?.expiry_month).padStart(2, '0');

  return (
    <div className="payment-detail-card">
      <FeaturedCard
        title={t('payment_method')}
        actions={
          permissions.update_payment_method
            ? [
                {
                  content: (submitLoad ? <Spinner size="small" /> : t('update')) as unknown as string,
                  onAction: sendLinkToUpdateCard,
                },
              ]
            : undefined
        }
        sectioned
      >
        <TextContainer spacing="tight">
          <p>
            {paymentMethod?.instrument?.brand}{' '}
            {t('card_information', { card_ending: String(paymentMethod?.instrument?.last_digits) })}
          </p>
          <Text as="span" variant="bodyMd" tone="subdued">
            {t('card_expire_info', {
              expire_date: `${expireMonth}/${paymentMethod?.instrument?.expiry_year?.toString()?.substr(-2)}`,
            })}
          </Text>
        </TextContainer>
      </FeaturedCard>
    </div>
  );
};

export default PaymentMethodDetails;
