import { BlockStack, Box, InlineStack, Modal, Text } from '@shopify/polaris';
import React, { useRef } from 'react';
import { SubscriptionContractLine } from 'types/SubscriptionContract';
import { ModalProps } from 'types/general';
import { SubscriptionItemRow } from './SubscriptionItemRow';

type Props = { contractLines: SubscriptionContractLine[]; onClose: () => void };

export const EditSubscriptionModal = ({ onClose, contractLines }: Props) => {
  const initialContractLines = useRef(contractLines);

  return (
    <Modal title="Edit your subscription items" onClose={onClose} open>
      <Modal.Section>
        <BlockStack gap="800">
          <BlockStack gap="200">
            <Text as="h6" variant="headingLg">
              Products
            </Text>
            <Text as="p" variant="bodyMd" tone="subdued">
              Add products to subscription rules then add specific delivery frequencies.
            </Text>
          </BlockStack>
          {contractLines.map((line, index) => (
            <SubscriptionItemRow line={line} index={index} />
          ))}
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};
