import React, { useContext, useState } from 'react';
import { BlockStack, Box, Button, Divider, Modal, Text } from '@shopify/polaris';

import { api } from 'api/index';
import ContractItemRow from './ContractItemRow';
import { useFrequencyIntervalInfo } from 'hooks/useFrequencyIntervalInfo';
import { FrequencyDataAdapter } from 'utils/FrequencyDataAdapter';
import { ContractFrequency } from '@/single-subscription/ContractFrequency';
import { PermissionsContext } from '../../pages/SingleSubscription';
import { t } from 'react-simple-multi-language';
import {
  ContractSellingPlan,
  SubscriptionContract,
  SubscriptionContractLine,
  UpdateSubscriptionContractPayload,
} from 'types/SubscriptionContract';
import { ModalProps } from 'types/general';
import { FeaturedCard } from '@/shared/FeaturedCard/FeaturedCard';
import { ProductSelectionModal } from '@/single-subscription/productSelection/ProductSelectionModal';
import { useEditSubscriptionLine } from 'hooks/useEditSubscriptionLine';
import { Product, ShortProduct, ShortProductVariant } from 'types/Product';
import { useFetchData } from 'hooks/useFetchData';
import { RawSellingPlan } from 'types/AdaptedSellingPlan';
import { NestedPartial } from 'types/TypeUtils';

type Props = {
  subscription: SubscriptionContract;
  items: SubscriptionContractLine[];
  updateSubscription: React.Dispatch<React.SetStateAction<SubscriptionContract>>;
  handleRemoveItem: (idsToRemove: string[]) => void;
} & ModalProps;

const ContractItemsModal = ({ isOpen, onClose, items, subscription, handleRemoveItem, updateSubscription }: Props) => {
  const { permissions } = useContext(PermissionsContext);

  const { interval, interval_count, billingPolicyIntervalCount } = useFrequencyIntervalInfo(subscription);

  const {
    linesList,
    clearTempData,
    removeLines,
    swapLines,
    updateLine,
    updateLinesFromVariantList,
    getUpdateLinesData,
  } = useEditSubscriptionLine(subscription.lines);

  const [products, loadingProducts] = useFetchData(() => api.getSubscribableProducts(subscription.id), {
    responseSelector: (resp: Product[]) => resp,
    initialData: [],
  });

  const [contractSellingPlan, setContractSellingPlan] = useState<ContractSellingPlan>({
    prePaidBillingCount: String(billingPolicyIntervalCount),
    intervals: String(interval_count),
    deliveryIntervals: interval,
  });

  const [swappingVariantId, setSwappingVariantId] = useState<null | number>(null);

  const [showProductSelectionModal, setShowProductSelectionModal] = useState(false);

  const [submitLoad, setSubmitLoad] = useState(false);

  const updateContractSellingPlanField = (value: string, key: keyof ContractSellingPlan) => {
    setContractSellingPlan((fields) => {
      return { ...fields, [key]: value };
    });
  };

  const handleUpdateItemsInfo = () => {
    setSubmitLoad(true);

    const sellingPlan = Object.assign({}, contractSellingPlan) as unknown as RawSellingPlan;
    const data: NestedPartial<UpdateSubscriptionContractPayload> = {
      ...getUpdateLinesData(),
    };

    if (
      sellingPlan.intervals !== String(interval_count) ||
      sellingPlan.deliveryIntervals !== interval ||
      sellingPlan.prePaidBillingCount !== String(billingPolicyIntervalCount)
    ) {
      data.delivery_policy = FrequencyDataAdapter.createDeliveryPolicy(sellingPlan).recurring;
      data.billing_policy = FrequencyDataAdapter.createBillingPolicy(sellingPlan).recurring;
    }

    api
      .updateSubscriptionContract(subscription?.id, data)
      .then((resp) => {
        updateSubscription(resp.data);
        setSubmitLoad(false);

        onClose();
      })
      .catch((err) => setSubmitLoad(false));
  };

  const closeModal = () => {
    onClose();
    clearTempData();
  };
  const closeProductSelectionModal = () => {
    if (swappingVariantId) {
      setSwappingVariantId(null);
    } else {
      setShowProductSelectionModal(!showProductSelectionModal);
    }
  };
  const updateSelectedProducts = (selectedProducts: ShortProduct[], selectedProductVariants: ShortProductVariant[]) => {
    const variants = [
      ...selectedProductVariants,
      ...selectedProducts
        .map((product) => product.variants.map((v) => ({ ...v, image_src: v.image_src || product.image_src })))
        .flat(1),
    ];
    updateLinesFromVariantList(variants);
  };

  const swapSelectedVariant = (_: ShortProduct[], selectedProductVariants: ShortProductVariant[]) => {
    const prevVariant = linesList.find((line) => swappingVariantId === line.id);
    const newVariant = selectedProductVariants[0];
    swapLines({
      newVariant: { ...newVariant, quantity: prevVariant?.quantity || 1 },
      prevVariantId: swappingVariantId!,
    });
    setSwappingVariantId(null);
  };

  const getSelectedLineList = () =>
    !!swappingVariantId ? linesList.filter((line) => line.id === swappingVariantId) : linesList;

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      title={t('product_details')}
      primaryAction={{ content: t('save'), onAction: handleUpdateItemsInfo, loading: submitLoad }}
      secondaryActions={[{ content: t('button_close'), onAction: closeModal }]}
    >
      <div className="edit-subscription-modal">
        <FeaturedCard>
          <Box paddingInline="500">
            {!!permissions.change_subscription_interval && (
              <ContractFrequency
                contractSellingPlan={contractSellingPlan}
                updateField={updateContractSellingPlanField}
              />
            )}
            <Box paddingBlock="500">
              <BlockStack gap="100">
                <Text variant="headingLg" as="h6" fontWeight="medium">
                  {t('products')}
                </Text>
                <Text variant="bodyMd" as="p" tone="subdued">
                  {t('product_list_description')}
                </Text>
              </BlockStack>
            </Box>
            <Divider />
            {(!!permissions.change_quantity ||
              !!permissions.add_subscription_line ||
              (items?.length > 1 && !!permissions.remove_subscription_line)) &&
              linesList.map((item) => (
                <ContractItemRow
                  key={item?.id}
                  item={item}
                  itemsCount={linesList?.length}
                  updateLine={updateLine}
                  removeLines={removeLines}
                  swapLine={setSwappingVariantId}
                />
              ))}
            {permissions.add_subscription_line && (
              <Box paddingBlockStart="500">
                <Button size="large" onClick={() => setShowProductSelectionModal(true)}>
                  {t('change_products')}
                </Button>
              </Box>
            )}
          </Box>
        </FeaturedCard>
      </div>
      <ProductSelectionModal
        selectedProductVariants={getSelectedLineList()}
        selectedProducts={[]}
        updateSelectedProducts={!!swappingVariantId ? swapSelectedVariant : updateSelectedProducts}
        toggleProductModal={closeProductSelectionModal}
        renderProductVariants
        handleLoadMoreProducts={() => {}}
        products={products}
        modifyProductFilter={() => {}}
        productsLoading={loadingProducts}
        openProductModal={showProductSelectionModal || !!swappingVariantId}
        isSingleSelection={!!swappingVariantId}
      />
    </Modal>
  );
};

export default ContractItemsModal;
