import { DatePicker, Modal, Range } from '@shopify/polaris';
import { useState } from 'react';

import { api } from 'api/index';
import { showToast } from 'states/actions/toast';
import { useDispatch } from 'react-redux';
import { t } from 'react-simple-multi-language';
import { ModalProps } from 'types/general';

type Props = ModalProps & {
  nextBillingDate: string | Date;
  contractId: number;
  updateNextBillingDate: (date: Date) => void;
};

const getDateConfig = (nextBillingDate: string | Date) => {
  const date = new Date(nextBillingDate);

  return { year: date.getFullYear(), month: date.getMonth() };
};

const getDisabledDate = () => {
  const date = new Date();

  date.setDate(date.getDate() + 1);

  return date;
};

export const SetNextBillingOrderModal = ({
  isOpen,
  onClose,
  nextBillingDate,
  contractId,
  updateNextBillingDate,
}: Props) => {
  const dispatch = useDispatch();

  const [dateConfig, setDateConfig] = useState(getDateConfig(nextBillingDate));
  const [selectedDate, setSelectedDate] = useState(new Date(nextBillingDate));

  const [submitLoading, setSubmitLoading] = useState(false);

  const onMonthChange = (month: number, year: number) => setDateConfig({ month, year });

  const onDateChange = (date: Range) => setSelectedDate(new Date(date.start));

  const submit = () => {
    setSubmitLoading(true);

    api
      .setNextBillingDate(contractId, selectedDate.toISOString())
      .then(({ data }) => {
        updateNextBillingDate(data.next_billing_date);
        setSubmitLoading(false);
        showToast(dispatch)(t('update_next_billing_message'));
        onClose();
      })
      .catch(() => {
        setSubmitLoading(false);
      });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={t('reschedule_next_order')}
      primaryAction={{
        content: t('save'),
        onAction: submit,
        loading: submitLoading,
      }}
      secondaryActions={[{ content: t('button_cancel'), onAction: onClose }]}
    >
      <Modal.Section>
        <DatePicker
          disableDatesBefore={getDisabledDate()}
          onMonthChange={onMonthChange}
          onChange={onDateChange}
          {...dateConfig}
          selected={selectedDate}
        />
      </Modal.Section>
    </Modal>
  );
};
