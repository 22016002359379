import validate from 'validate.js';

import { constraints } from 'config/formFieldConstraints';

export const formValidation = (fieldName: string, value: unknown) => {
  const formValues: { [key: string]: unknown } = {};
  formValues[fieldName] = value;

  const formFields: { [key: string]: unknown } = {};
  formFields[fieldName] = constraints[fieldName as keyof typeof constraints];

  const result = validate(formValues, formFields);

  if (result) return result[fieldName][0];

  return null;
};
