import { Badge, Banner, InlineStack } from '@shopify/polaris';
import { t } from 'react-simple-multi-language';

const FailedPaymentBanner = () => {
  return (
    <div style={{ marginBottom: 15 }}>
      <Banner tone="critical">
        <InlineStack blockAlign="center" gap="300">
          <p>{t('last_payment')}</p>
          <Badge tone="critical">{t('status_failed')}</Badge>
        </InlineStack>
      </Banner>
    </div>
  );
};

export default FailedPaymentBanner;
