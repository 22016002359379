import { BlockStack, Text } from '@shopify/polaris';

import { LoginFormLinks } from './LoginFormLinks';
import { useLoginSteps } from './useLoginSteps';
import { useLoginForm } from './useLoginForm';
import { FeaturedCard } from '@/shared/FeaturedCard/FeaturedCard';

export const Login = () => {
  const {
    stepId,
    goToNextStep,
    email,
    onChangeEmail,
    sendEmailLoading,
    emailErrorMessage,
    stores,
    onUpdateStores,
    onRequestCode,
  } = useLoginForm();

  const loginSteps = useLoginSteps({ email });

  const selectedStep = loginSteps.find((step) => step.id === stepId)!;
  const StepComponent = selectedStep.component;

  return (
    <div className="otp-container">
      <div className="otp-form-container">
        <FeaturedCard sectioned>
          <BlockStack gap="200">
            <div style={{ textAlign: 'center' }}>
              <BlockStack inlineAlign="center" align="center" gap="300">
                <Text as="span" variant="bodyLg" fontWeight="semibold">
                  {selectedStep.title}
                </Text>
                {!!selectedStep?.subtitle && (
                  <Text as="span" variant="bodyMd" tone="subdued">
                    {selectedStep.subtitle}
                  </Text>
                )}
              </BlockStack>
            </div>

            <StepComponent
              email={email}
              onChangeEmail={onChangeEmail}
              sendEmailLoading={sendEmailLoading}
              emailErrorMessage={emailErrorMessage}
              stores={stores}
              onUpdateStores={onUpdateStores}
              goToNextStep={goToNextStep}
              onRequestCode={onRequestCode}
            />

            <LoginFormLinks />
          </BlockStack>
        </FeaturedCard>
      </div>
    </div>
  );
};
