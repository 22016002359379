import { ClientApplication } from '@shopify/app-bridge';

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { baseUrl } from 'config/baseUrl';
import { PATHS } from 'config/constants';
import { LoginWithAuthParamsRequest, LoginWithAuthParamsResponse, OtpLoginResponse } from 'types/Authentication';
import { CancellationManagement, CancellationReasonsData } from 'types/CancelationManagement';
import { Customer } from 'types/Customer';
import { CustomerPermissions } from 'types/CustomerPermissions';
import { PortalCustomizationInfo } from 'types/Customization';
import { DiscountInfo } from 'types/DiscountInfo';
import { Product } from 'types/Product';
import {
  SubscriptionContract,
  SubscriptionContractsRequestPayload,
  UpdateSubscriptionContractPayload,
} from 'types/SubscriptionContract';
import { NestedPartial, PaginatedResponseType } from 'types/TypeUtils';
import { ShopBrandingInfo } from 'types/shop';
import { LoginWithAuthParams } from 'utils/LoginWithAuthParams';
import { errorHandler } from 'utils/errorHandler';

class PublicApi extends LoginWithAuthParams {
  public app!: ClientApplication<any>;
  protected readonly axios: AxiosInstance;

  constructor(baseUrl: string) {
    super();
    this.axios = axios.create();
    this.axios.defaults.baseURL = baseUrl;
    this.axios.defaults.timeout = 90000;
    this.axios.defaults.headers['Content-Type'] = 'application/json';

    this.axios.interceptors.request.use((config) => {
      config.headers['PORTAL-REQUEST'] = true;
      return config;
    });
  }

  _handleErrors(err: AxiosError) {
    if (err?.response?.status === 401) {
      if (this.isAuthParamsExist()) {
        this.loginWithAuthParams(this.getAuthTokenFromAuthUrlParams);
      } else if (!err?.response?.config.url?.includes('/portal/login/otp')) {
        localStorage.removeItem('loggedInAsUserToken');
        window.open(window.location.origin + PATHS.LOGIN, '_self');
      }
    }

    return Promise.reject(errorHandler(err));
  }

  async get<T>(...params: [string, any?]): Promise<AxiosResponse<T>> {
    return this.axios.get(...params).catch((err) => this._handleErrors(err));
  }

  async post<T>(url: string, data?: any, config?: AxiosRequestConfig | undefined): Promise<AxiosResponse<T>> {
    return this.axios.post(url, data, config).catch((err) => this._handleErrors(err));
  }

  async patch<T>(...params: [string, any?]): Promise<AxiosResponse<T>> {
    return this.axios.patch(...params).catch((err) => this._handleErrors(err));
  }

  async put<T>(...params: [string, any?]): Promise<AxiosResponse<T>> {
    return this.axios.put(...params).catch((err) => this._handleErrors(err));
  }

  async delete<T>(...params: [string, any?]): Promise<AxiosResponse<T>> {
    return this.axios.delete(...params).catch((err) => this._handleErrors(err));
  }

  getAuthTokenFromAuthUrlParams = (data: LoginWithAuthParamsRequest) => {
    return this.post<LoginWithAuthParamsResponse>('/portal/login/store-front/', data);
  };

  requestAuthCode(email: string) {
    return this.post('/portal/login/otp/send-code', { email });
  }

  verifyAuthCode(email: string, otp: string) {
    return this.post<OtpLoginResponse>('/portal/login/otp/verify', { email, otp });
  }
}

class Api extends PublicApi {
  constructor(baseUrl: string) {
    super(baseUrl);

    this.axios.interceptors.request.use((config) => {
      config.headers['Authorization'] = this._getToken();
      return config;
    });
  }

  _getToken() {
    const token = localStorage.getItem('loggedInAsUserToken');

    if (!token) return '';

    return `Bearer ${token}`;
  }

  // Contracts
  getSubscriptionContracts(data?: SubscriptionContractsRequestPayload) {
    return this.get<PaginatedResponseType<SubscriptionContract[]>>(`/portal/subscription_contracts/`, data);
  }

  getSubscriptionContract(contractId: number) {
    return this.get<SubscriptionContract>(`/portal/subscription_contracts/${contractId}/`);
  }

  updateSubscriptionContract(contractId: number, data: NestedPartial<UpdateSubscriptionContractPayload>) {
    return this.patch<SubscriptionContract>(`/portal/subscription_contracts/${contractId}/`, data);
  }

  pauseSubscriptionContract = (id: number) => {
    return this.post(`/portal/subscription_contracts/${id}/pause/`);
  };

  resumeSubscriptionContract = (id: number) => {
    return this.post(`/portal/subscription_contracts/${id}/resume/`);
  };

  cancelSubscriptionContract = (id: number, reasonData?: CancellationReasonsData) => {
    return this.post(`/portal/subscription_contracts/${id}/cancel/`, reasonData);
  };

  skipBilling(contractId: number) {
    return this.patch<Pick<SubscriptionContract, 'next_billing_date'>>(
      `/portal/subscription_contracts/${contractId}/skip_next_billing/`
    );
  }

  setNextBillingDate(contractId: number, date: string) {
    return this.patch<SubscriptionContract>(`/portal/subscription_contracts/${contractId}/set_next_billing_date/`, {
      date,
    });
  }

  getSubscribableProducts = (contractId: number) => {
    return this.get<Product[]>(`/portal/subscription_contracts/${contractId}/subscribable-products/`);
  };

  // Customers
  updateCustomerInfo(data: Pick<Customer, 'email' | 'first_name' | 'last_name'>) {
    return this.patch<Customer>(`/portal/customers`, data);
  }

  sendEmailToUpdatePaymentMethod(paymentMethodId: string) {
    return this.post<{ customer_id: number }>(`/portal/customer_payment_methods/${paymentMethodId}/send_update_email/`);
  }

  // permissions
  getCustomerPermissions() {
    return this.get<CustomerPermissions>('/portal/settings/customer-permissions');
  }

  // discount
  applyDiscountCode(contractId: number, discount_code: string) {
    return this.patch<DiscountInfo>(`/portal/subscription_contracts/${contractId}/apply_discount_code/`, {
      discount_code,
    });
  }

  getShopBrandInformation() {
    return this.get<ShopBrandingInfo>('/shop-settings/portal/brand/');
  }

  // cancelation message
  getCancelationMAnagementData() {
    return this.get<CancellationManagement>('/portal/settings/cancellation-management');
  }

  // customer info
  getCustomerInfo() {
    return this.get<Customer>('/portal/customers');
  }

  // portal contents
  getContents() {
    return this.get<PortalCustomizationInfo>('/portal/settings/customization/');
  }
}

export const publicApi = new PublicApi(baseUrl);
export const api = new Api(baseUrl);
